import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 64px;
  box-shadow: 0 20px 10px -10px rgba(0, 0, 0, 0.08);
  min-height: 100px;
`;
export const StyledHeaderCommon = styled(StyledHeader)`
  justify-content: space-between;
`;

export const StyledLeftPart = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 54px;
`;

export const StyledLeftPartCommon = styled(StyledLeftPart)`
  gap: 0;
`;

export const StyledRightPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const StyledRightPartPatients = styled(StyledRightPart)`
  justify-content: flex-end;
  gap: 80px;
`;
