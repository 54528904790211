export const caseIdUrlParam = ':caseId';
export let ROUTES = {
  LOGIN: '/login',
  PATIENTS_LIST: '/patients',
  PATIENTS_CASE_EDIT: '',
};
ROUTES.PATIENTS_CASE_EDIT = `${ROUTES.PATIENTS_LIST}/${caseIdUrlParam}`;
ROUTES = Object.freeze(ROUTES);

export function getDir(lng) {
  const langs = {
    en: 'ltr',
    he: 'rtl',
    ru: 'ltr',
    fr: 'ltr',
    cn: 'ltr',
  };
  return langs?.[lng] ?? 'ltr';
}
