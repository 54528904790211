import * as ADMIN_TYPES from './admin.types';

import {
  EDoctorPermissions,
  languages,
  languagesObject,
} from '../../helpers/constants';

export const DEFAULT_VACATION = {
  start_vacation: new Date(),
  end_vacation: new Date(),
  key: 'selection',
};

export const initialAdminState = {
  user: '',
  admin: '',
  phone: '',
  permission: EDoctorPermissions.user,
  signature: '',
  stamp: '',
  stamp_blob_link: '',
  profile_image: '',
  profile_image_blob_link: '',
  isActive: true,
  vacation: DEFAULT_VACATION,
  isVacationSet: false,
  doctorManagement: {},
  isAdminChangesLoading: false,
  isSaveDetailsLoading: false,
  isSaveDetailsSuccess: false,
  isStampLoading: false,
  isProfileImageLoading: false,
  physicianPrefix: '',
  loadingActDeact: false,
  ai_networks: null,
  degree: '',
  license: '',
  showImages: false,
  clinic_phone: '',
  address_clinic: '',
  clinic_address: '',
  expert_license: '',
  smsNotification: false,
  physician_details: '',
  clinic_address_second_part: '',
  clinic_city: '',
  clinic_region: '',
  clinic_country: '',
  clinic_zip: '',
  clinic_linkFaceBook: '',
  clinic_linkInstagram: '',
  clinic_linkLinkedin: '',
  clinic_linkUrl: '',
};

const defualtLangState = {
  name: '',
};

for (let i = 0; i < languages.length; i++) {
  const lang = languages[i].lang;
  initialAdminState[lang] = defualtLangState;
}

const adminReducer = (state = initialAdminState, action) => {
  let lang = languagesObject.HE;
  switch (action.type) {
    case ADMIN_TYPES.UPDATE_PHYSICIAN_NAME_PREFIX:
      return {
        ...state,
        namePrefixObject: action.payload,
      };
    // save physician details
    case ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS:
      return {
        ...state,
        isSaveDetailsLoading: true,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload.admin_settings,
        isSaveDetailsLoading: false,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.error,
        isSaveDetailsLoading: false,
      };
    //

    // save physician profile_image
    case ADMIN_TYPES.SAVE_PHYSICIAN_STAMP:
      return {
        ...state,
        isStampLoading: true,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_STAMP_SUCCESS:
      return {
        ...state,
        stamp: action.payload.stamp,
        isStampLoading: false,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_STAMP_FAIL:
      return {
        ...state,
        error: action.error,
        isStampLoading: false,
      };
    //

    // save physician profile_image
    case ADMIN_TYPES.SAVE_PHYSICIAN_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        isProfileImageLoading: true,
      };
    case ADMIN_TYPES.savePhysicianProfileImageSuccess:
      return {
        ...state,
        profile_image: action.payload.profile_image,
        isProfileImageLoading: false,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        isProfileImageLoading: false,
      };
    //

    // get physician stamp
    case ADMIN_TYPES.GET_PHYSICIAN_STAMP_REQUEST:
      return {
        ...state,
        isStampLoading: true,
      };
    case ADMIN_TYPES.GET_PHYSICIAN_STAMP_SUCCESS:
      return {
        ...state,
        stamp_blob_link: action.payload.link,
        isStampLoading: false,
      };
    case ADMIN_TYPES.getPhysicianStampFail:
      return {
        ...state,
        error: action.payload.error,
        isStampLoading: false,
      };
    //

    // get physician profile_image
    case ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        isProfileImageLoading: true,
      };
    case ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        profile_image_blob_link: action.payload.link,
        isProfileImageLoading: false,
      };
    case ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload.error,
        isProfileImageLoading: false,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_SUCCESS:
    case ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_FAIL:
      return {
        ...state,
        isAdminChangesLoading: false,
      };
    case ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_START:
      return {
        ...state,
        isAdminChangesLoading: true,
      };
    case ADMIN_TYPES.SET_IS_VACATION_ACTIVATED:
      return {
        ...state,
        isVacationSet: action.payload,
      };
    case ADMIN_TYPES.UPDATE_DOCTOR_MANAGMENT_BY_PHYSICIAN:
      return {
        ...state,
        doctorManagement: action.payload.doctorManagement,
      };
    case ADMIN_TYPES.UPDATE_USER_ACTIVITY:
      return {
        ...state,
        isActive: action.payload.isActive,
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_VACATION_PERIOD:
      return {
        ...state,
        vacation: action.payload,
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_SIGNATURE:
      return {
        ...state,
        signature: action.payload.signature,
      };
    case ADMIN_TYPES.UPDATE_PHYSICIAN_PREFIX:
      return {
        ...state,
        physicianPrefix: action.payload.physicianPrefix,
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_ALL_NAMES:
      return {
        ...state,
        [languagesObject.HE]: action.payload.names[languagesObject.HE],
        [languagesObject.EN]: action.payload.names[languagesObject.EN],
        name: action.payload.names.name,
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_NAME:
      return {
        ...state,
        [action.payload.lang]: {
          ...state[action.payload.lang],
          name: `${action.payload.name}`,
        },
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_PHONE:
      return {
        ...state,
        phone: action.payload.phone,
      };
    case ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case ADMIN_TYPES.UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_SUCCESS:
      return {
        ...state,
      };
    case ADMIN_TYPES.SET_LOADING_ACT_DEACT:
      return {
        ...state,
        loadingActDeact: action.payload,
      };
    case ADMIN_TYPES.SET_ADMIN_SETTINGS:
      let adminSettings = action.payload.adminSettings;

      if (adminSettings && adminSettings.name) {
        if (
          !adminSettings[languagesObject.HE] ||
          (adminSettings[languagesObject.HE] &&
            !adminSettings[languagesObject.HE].name)
        ) {
          adminSettings[languagesObject.HE] = defualtLangState;
          adminSettings[languagesObject.HE].name = '';
        }

        if (
          !adminSettings[languagesObject.EN] ||
          (adminSettings[languagesObject.EN] &&
            !adminSettings[languagesObject.EN].name)
        ) {
          adminSettings[languagesObject.EN] = defualtLangState;
          adminSettings[languagesObject.EN].name = '';
        }
      }

      return {
        ...state,
        ...{
          ...action.payload.adminSettings,
          [lang]: action.payload.adminSettings
            ? action.payload.adminSettings[lang]
              ? action.payload.adminSettings[lang]
              : defualtLangState
            : null,
          user: action.payload.admin,
          admin: action.payload.admin,
          permission: action.payload.permission,
          isActive: action.payload.is_active,
          vacation: action.payload.vacation,
          isVacationSet: action.payload.isVacationSet,
          doctorManagement: {
            [action.payload.user]: {
              admin_settings: action.payload.adminSettings,
            },
          },
        },
      };
    case ADMIN_TYPES.RESET_ADMIN_SETTINGS:
      return {
        ...state,
        ...initialAdminState,
      };
    default:
      return state;
  }
};

export default adminReducer;
