import { axiosInstance } from '../core/axios';

export const getAuditAnnotationsByUuid = async (uuid) => {
  try {
    const { data } = await axiosInstance.get(`/api/audit-annotation/${uuid}`);
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const createAuditAnnotation = async (uuid, body) => {
  try {
    const { data } = await axiosInstance.post(
      `/api/audit-annotation/${uuid}`,
      body
    );
    return data;
  } catch (error) {
    console.log('Error creation of audit annotation: \n', error);
    return false;
  }
};
