const prefixes = Object.freeze({
  en: { Dr: "Dr.", Prof: "Prof.", Mr: "Mr." },
  he: { Dr: "דר.", Prof: "פרופ.", Mr: "מר." },
  ru: { Dr: "Доктор", Prof: "Профессор", Mr: "Господин" },
  fr: { Dr: "Dr.", Prof: "Prof.", Mr: "Mr." },
  cn: { Dr: "博士", Prof: "教授", Mr: "先生" }
});

export const getPrefixForLang = (pfx, lang) => {
  if (!pfx || !lang) return "";
  const translations = Object.values(prefixes);

  const key = translations.reduce((acc, translation) => {
    const pair = Object.entries(translation).find((pair) => {
      return pair.includes(pfx);
    });
    if (pair) {
      const [key] = pair;
      return key;
    }
    return acc;
  }, "");

  return prefixes[lang][key];
};