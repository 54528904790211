import * as NOTIFICATIONS_TYPES from './notifications.types';
const initialNotificationState = {
  isOpen: false,
  isToastOpen: false,
  toastStatus: 'error',
  toastMessage: '',
};

const notificationsReducer = (
  state = initialNotificationState,
  { type, payload }
) => {
  switch (type) {
    case NOTIFICATIONS_TYPES.OPEN_TOAST:
      return {
        ...state,
        isToastOpen: true,
        toastStatus: payload.status,
        toastMessage: payload.message,
      };
    case NOTIFICATIONS_TYPES.CLOSE_TOAST:
      return {
        ...state,
        isToastOpen: false,
        toastStatus: state?.toastStatus || 'error',
        toastMessage: '',
      };
    default:
      return state;
  }
};

export default notificationsReducer;
