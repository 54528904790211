import { initPatientListState } from './patientList.reducer';

export const getPageOffset = ({ patientList }) => patientList.offset;
export const getPageLimit = ({ patientList }) => patientList.limit;
export const getPageNumber = ({ patientList }) => patientList.page;
export const getPatientsTotal = ({ patientList }) => patientList.totalCount;
export const getGetPatientsSubscription = ({ patientList }) =>
  patientList.subscribePatients;
export const getPageOrderBy = ({ patientList }) => patientList.orderBy;

export const getAllPatientListFilters = ({
  patientList: {
    limit,
    offset,
    efficiencyReport,
    order,
    page,
    statuses,
    modifiedAt,
    globalFilterString,
    childAgeRestriction,
    age,
    gender,
    valueDiagnosedAt,
    valueDiagnosedBy,
    valuePatientId,
    valueCaseId,
    valueCreatedTime,
    dateRange,
    orderCreatedAt,
  },
}) => ({
  limit,
  offset,
  efficiencyReport,
  order,
  page,
  statuses,
  modifiedAt,
  globalFilterString: globalFilterString || null,
  childAgeRestriction,
  age,
  gender,
  valueDiagnosedAt,
  valueDiagnosedBy,
  valuePatientId,
  valueCaseId,
  valueCreatedTime,
  dateRange,
  orderCreatedAt,
});

export const getAllPatientListInitialFilters = () => {
  const {
    limit,
    offset,
    efficiencyReport,
    order,
    page,
    statuses,
    modifiedAt,
    globalFilterString,
    childAgeRestriction,
    age,
    gender,
    valueDiagnosedAt,
    valueDiagnosedBy,
    valuePatientId,
    valueCaseId,
    valueCreatedTime,
    dateRange,
    orderCreatedAt,
  } = initPatientListState;
  return {
    limit,
    offset,
    valueDiagnosedAt,
    valueDiagnosedBy,
    valuePatientId,
    efficiencyReport,
    order,
    page,
    statuses,
    modifiedAt,
    globalFilterString: globalFilterString || null,
    childAgeRestriction,
    valueCaseId,
    valueCreatedTime,
    dateRange,
    age,
    gender,
    orderCreatedAt,
  };
};
