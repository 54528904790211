import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  menuDivContainer: {
    position: 'relative',
  },
  menuAbsolute: {
    position: 'absolute',
    marginTop: '100px',
  },
  menuItemPadding: {
    padding: '12px 25px',
    justifyContent: 'center',
    color: '#0381A0'
  },
  iconButton: {
    padding: '5px',
    outline: 'none'
  }
}));