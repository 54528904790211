import React from 'react';
import { IconButton, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

const LangMenuItem = ({
  classNameMenuItem,
  onClick,
  imgSource,
  alternative,
  currentLang,
  classNameSpan
}) => {
  return (
    <>
      <MenuItem
        onClick={onClick}
        className={classNameMenuItem}
      >
      <IconButton
        color="inherit"
        style={{display: 'flex', justifyContent: 'space-between', textAlign: 'center'}}
      >
        <div >
          <img className="langFlag" src={imgSource} alt={alternative} width={30} />
          <span className={classNameSpan}>{currentLang}</span>
          <span></span>
        </div>
      </IconButton>
      </MenuItem>
      <Divider variant="middle" />
    </>
  );
}

export default LangMenuItem;