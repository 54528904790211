import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import userProfile from '../../../../assets/svg/userProfile.svg';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { logout as logoutService } from '../../../../services/auth.service';
import { resetAdminSettings } from '../../../../store/admin/admin.actions';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { setSettingsUser } from '../../../../store/settings/settings.actions';
import IconButton from '../../../shared/IconButton';

export const SettingsButton = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const s = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    return logoutService().then((data) => {
      dispatch(
        setSettingsUser({
          user: '',
          vendor: '',
          permission: '',
          admin_settings: {},
        })
      );
      dispatch(resetAdminSettings());
      localStorage.removeItem('access_token');

      history.push('/login');
    });
  };

  return (
    <div className={s.menuDivContainer}>
      <IconButton onClick={handleMenu} src={userProfile} alt="userProfile" />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        className={s.menuAbsolute}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            history.push('/user-management');
          }}
          className={s.menuItemPadding}
        >
          {t('sidebar.personalDetails')}
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            logout();
          }}
          className={s.menuItemPadding}
        >
          {t('sidebar.logout')}
        </MenuItem>
        <Divider variant="middle" />
      </Menu>
    </div>
  );
});
