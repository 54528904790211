import { all } from 'redux-saga/effects';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';

import adminReducer from './admin/admin.reducer';
import analyticReducer from './analytic/analytic.reducer';
import auditAnnotationsReducer from './auditAnnotations/auditAnnotationsReducer';
import authReducer from './auth/auth.reducer';
import patientDetailsReducer from './patientDetails/patientDetails.reducer';
import diseasesReducer from './diseases/diseases.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import patientListReducer from './patientList/patientList.reducer';
import patientHistoryReducer from './patientHistory/patientHistory.reducer';
import physiciansReducer from './physicians/physicians.reducer';
import settingReducer from './settings/settings.reducer';

import adminSagas from './admin/admin.sagas';
import analyticSagas from './analytic/analytic.sagas';
import auditAnnotationsSagas from './auditAnnotations/auditAnnotations.sagas';
import authSagas from './auth/auth.saga';
import patientDetailsSagas from './patientDetails/patientDetails.sagas';
import diseaseSagas from './diseases/diseases.sagas';
import patientListSagas from './patientList/patientList.sagas';
import patientHistorySagas from './patientHistory/patientHistory.sagas';
import physiciansSagas from './physicians/physicians.sagas';
import settingsSagas from './settings/settings.sagas';

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: settingReducer,
    diseases: diseasesReducer,
    patientHistory: patientHistoryReducer,
    patientDetails: patientDetailsReducer,
    notifications: notificationsReducer,
    patientList: patientListReducer,
    analytic: analyticReducer,
    auth: authReducer,
    admin: adminReducer,
    auditAnnotations: auditAnnotationsReducer,
    physicians: physiciansReducer,
  });

const initialState = {};
export const history = createBrowserHistory();

const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools || compose;

const routerMW = routerMiddleware(history);

export const store = createStore(
  rootReducer(history),
  initialState,
  composeEnhancers(applyMiddleware(routerMW, sagaMiddleware))
);

function* allSaga() {
  yield all([
    ...adminSagas,
    ...auditAnnotationsSagas,
    ...authSagas,
    ...patientListSagas,
    ...analyticSagas,
    ...diseaseSagas,
    ...patientDetailsSagas,
    ...physiciansSagas,
    ...patientHistorySagas,
    ...settingsSagas,
  ]);
}
sagaMiddleware.run(allSaga);

export default store;
