import { fork, put, takeLatest, select, call } from 'redux-saga/effects';
import * as ADMIN_TYPES from './admin.types';
import {
  updateCurrentPhysician,
  updateCurrentPhysicianName,
  updateCurrentPhysicianPhone,
  updateCurrentPhysicianSignature,
  updateDoctorManagemtByPhysician,
  savePhysicianInformationRequestStart,
  savePhysicianInformationSuccess,
  savePhysicianInformationFail,
  updateUserActivity,
  updateCurrentPhysicianVocation,
  setActivateVacation,
  setLoadingActDeact,
  updateCurrentPhysicianAllNames,
  savePhysicianDetailsFail,
  savePhysicianDetailSuccess,
  savePhysicianStampSuccess,
  savePhysicianStampFail,
  getPhysicianStampSuccess,
  savePhysicianProfileImageSuccess,
  savePhysicianProfileImageFail,
  getPhysicianProfileImageSuccess,
  updateAdminSettingsByPhysicianRequest,
} from './admin.actions';
import { openToast } from '../notifications/notifications.actions';
import {
  getCurrentSignature,
  getCurrentUserPhone,
  getDoctorManagement,
  getCurrentUser,
  getCurrentVacation,
  getPhysicianPrefix,
  getUserNameInAllLanguages,
  getCurrentStamp,
  getCurrentProfileImage,
} from './admin.selectors';
import rest from '../../services/main.service';
import { l } from '../../i18n';
import { VENDORS_TYPES } from '../../components/helpers/groups';
import { getLanguage } from '../settings/settings.selectors';
import { updateIsOnVacation } from '../../store/settings/settings.actions';
import { languagesObject } from '../../helpers/constants';

export const VACATION_REMOVE_TYPE = Object.freeze({
  removeVacation: 'REMOVE_VACATION',
  persistVacation: 'PERSIST_VACATION',
});

function* getDoctorDetailsWORK({ payload: email }) {
  try {
    const docDetails = yield call(rest.getDoctorDetails, email);
    if (!docDetails || docDetails.vendor_id !== VENDORS_TYPES.maccabi) {
      return;
    }
    const doctorManagement = yield select(getDoctorManagement);
    doctorManagement[email] = docDetails.admin_settings;
    const lang = yield select(getLanguage);

    if (docDetails.admin_settings) {
      const rec = ' ';
      const {
        phone = rec,
        signature,
        vacation = {},
      } = docDetails.admin_settings;

      if (!docDetails.admin_settings.name) {
        docDetails.admin_settings.name = '';
      }

      yield put(updateCurrentPhysicianAllNames(docDetails.admin_settings));

      yield put(updateCurrentPhysicianPhone(phone));
      yield put(updateCurrentPhysicianSignature(signature));
      const isVacation =
        vacation && vacation.start_vacation && vacation.end_vacation;
      const defDate = new Date().toISOString();
      if (isVacation) {
        yield put(setActivateVacation(true));
      } else {
        yield put(setActivateVacation(false));
      }
      yield put(
        updateCurrentPhysicianVocation({
          start_vacation: isVacation
            ? new Date(vacation.start_vacation)
            : new Date(defDate),
          end_vacation: isVacation
            ? new Date(vacation.end_vacation)
            : new Date(defDate),
          key: 'selection',
        })
      );

      yield put(savePhysicianInformationSuccess());
    } else {
      yield put(updateCurrentPhysicianName({ name: '', lang: lang }));
      yield put(updateCurrentPhysicianPhone(' '));
      yield put(updateCurrentPhysicianSignature(''));

      yield put(savePhysicianInformationSuccess());
    }
    yield put(updateUserActivity(docDetails.is_active));
    yield put(updateCurrentPhysician(docDetails.doctor_email));
    yield put(updateDoctorManagemtByPhysician({ ...doctorManagement }));
    yield put(setLoadingActDeact(false));
  } catch (e) {
    console.log(e);
  }
}

function* savePhysicianInformationWORK({ payload: isRemoveVacation }) {
  try {
    yield put(setLoadingActDeact(true));
    yield put(savePhysicianInformationRequestStart());
    const user = yield select(getCurrentUser);
    const namesInAllLanguages = yield select(getUserNameInAllLanguages);
    const phone = yield select(getCurrentUserPhone);
    const signature = yield select(getCurrentSignature);
    const curVacation = yield select(getCurrentVacation);
    const physicianPrefix = yield select(getPhysicianPrefix);

    const isPersistVacation =
      isRemoveVacation === VACATION_REMOVE_TYPE.persistVacation;
    const timeRegEx = /T.+/;

    const vacation = isPersistVacation
      ? {
          start_vacation: curVacation.start_vacation
            .toISOString()
            .replace(timeRegEx, 'T00:00:00.000Z'),
          end_vacation: curVacation.end_vacation
            .toISOString()
            .replace(timeRegEx, 'T23:59:59.999Z'),
        }
      : null;

    const response = yield call(rest.updatePhysicianData, {
      email: user,
      adminSettings: {
        name: namesInAllLanguages.name,
        phone,
        signature,
        physicianPrefix,
        [languagesObject.EN]: {
          name: namesInAllLanguages[languagesObject.EN],
        },
        [languagesObject.HE]: {
          name: namesInAllLanguages[languagesObject.HE],
        },
        vacation,
      },
      isRemoveVacation: isRemoveVacation,
    });
    if (response.success) {
      yield put(updateIsOnVacation(response.isOnVacation));
    }

    yield put(updateAdminSettingsByPhysicianRequest(user));

    if (isPersistVacation) {
      yield put(setActivateVacation(true));
      yield put(openToast(l('snackbar.activated')));
    } else {
      yield put(setActivateVacation(false));
      yield put(openToast(l('snackbar.deactivated')));
    }
    yield put(savePhysicianInformationSuccess());
  } catch (e) {
    console.log(`save physician information failed.`);
    console.log(e);
    yield put(setActivateVacation(false));
    yield put(savePhysicianInformationFail(e));
    yield put(openToast(l('managementPage.savePhysicianError'), 'error'));
  } finally {
    yield put(setLoadingActDeact(false));
  }
}

function* savePhysicianDetailsWORK(action) {
  try {
    const { physicianData } = action.payload;
    const {
      name,
      surname,
      degree,
      license,
      expert_license,
      clinic_phone,
      clinic_address,
      physician_details,
      physician_phone: phone,
      physician_prefix: physicianPrefix,
      clinic_address_second_part,
      clinic_city,
      clinic_region,
      clinic_country,
      clinic_zip,
      clinic_linkFaceBook,
      clinic_linkInstagram,
      clinic_linkLinkedin,
      clinic_linkUrl,
    } = physicianData;

    const fullName = `${name.trim()} ${surname.trim()}`;
    const signature = yield select(getCurrentSignature);
    const stamp = yield select(getCurrentStamp);
    const profile_image = yield select(getCurrentProfileImage);
    const lang = yield select(getLanguage);

    const dataToUpdate = {
      adminSettings: {
        phone,
        signature,
        stamp,
        physicianPrefix,
        clinic_phone,
        clinic_address,
        degree,
        license,
        expert_license,
        physician_details,
        [lang]: { name: fullName },
        clinic_address_second_part,
        clinic_city,
        clinic_region,
        clinic_country,
        clinic_zip,
        profile_image,
        clinic_linkFaceBook,
        clinic_linkInstagram,
        clinic_linkLinkedin,
        clinic_linkUrl,
      },
    };

    const result = yield call(rest.updatePhysicianDetails, dataToUpdate);
    yield put(savePhysicianDetailSuccess(result));
    yield put(openToast(l('snackbar.saved')));
  } catch (e) {
    console.log(`save physician details failed.`);
    console.log(e);
    yield put(savePhysicianDetailsFail(e));
  }
}

function* savePhysicianStampWORK(action) {
  try {
    const { file, type } = action.payload;

    const result = yield call(rest.savePhysicianImage, { file, type });
    if (result?.success) yield put(savePhysicianStampSuccess(result));
  } catch (e) {
    console.log(`save physician stamp failed.`, e);
    yield put(savePhysicianStampFail(e));
  }
}

function* getPhysicianStampWORK(action) {
  try {
    const { fileName } = action.payload;

    const result = yield call(rest.getPhysicianImage, fileName);
    yield put(getPhysicianStampSuccess(result));
  } catch (e) {
    console.log(`get physician stamp failed.`, e);
    yield put(savePhysicianStampFail(e));
  }
}
function* savePhysicianProfileImageWORK(action) {
  try {
    const { file, type } = action.payload;

    const result = yield call(rest.savePhysicianImage, { file, type });
    if (result?.success) yield put(savePhysicianProfileImageSuccess(result));
  } catch (e) {
    console.log(`save physician profile image failed.`, e);
    yield put(savePhysicianProfileImageFail(e));
  }
}

function* getPhysicianProfileImageWORK(action) {
  try {
    const { fileName } = action.payload;

    const result = yield call(rest.getPhysicianImage, fileName);
    yield put(getPhysicianProfileImageSuccess(result));
  } catch (e) {
    console.log(`get physician profile image failed.`, e);
    yield put(savePhysicianProfileImageFail(e));
  }
}

function* watchSavePhysicianDetails() {
  yield takeLatest(
    ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS,
    savePhysicianDetailsWORK
  );
}

function* watchSavePhysicianStamp() {
  yield takeLatest(ADMIN_TYPES.SAVE_PHYSICIAN_STAMP, savePhysicianStampWORK);
}

function* watchSavePhysicianProfileImage() {
  yield takeLatest(
    ADMIN_TYPES.SAVE_PHYSICIAN_PROFILE_IMAGE_REQUEST,
    savePhysicianProfileImageWORK
  );
}

function* watchGetPhysicianStamp() {
  yield takeLatest(
    ADMIN_TYPES.GET_PHYSICIAN_STAMP_REQUEST,
    getPhysicianStampWORK
  );
}

function* watchGetPhysicianProfileImage() {
  yield takeLatest(
    ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_REQUEST,
    getPhysicianProfileImageWORK
  );
}

function* watchSavePhysicianInformation() {
  yield takeLatest(
    ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION,
    savePhysicianInformationWORK
  );
}

function* watchGetDoctorDetails() {
  yield takeLatest(
    ADMIN_TYPES.UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN,
    getDoctorDetailsWORK
  );
}

const adminSagas = [
  fork(watchGetDoctorDetails),
  fork(watchSavePhysicianInformation),
  fork(watchSavePhysicianDetails),
  fork(watchSavePhysicianStamp),
  fork(watchSavePhysicianProfileImage),
  fork(watchGetPhysicianStamp),
  fork(watchGetPhysicianProfileImage),
];

export default adminSagas;
