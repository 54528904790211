import { call, put, fork, select, takeLatest } from '@redux-saga/core/effects';
import * as PATIENT_DETAILS_TYPES from './patientDetails.types';

import {
  createTreatmentPlanSuccess,
  createTreatmentPlanFail,
  updateAwaitingCasesArray,
  getAnnotations,
  changeStatusState,
  blockCasesBatchStart,
  blockCasesBatchSuccess,
  blockCasesBatchFail,
  fetchAnnotationsStart,
  setAnotations,
  patientDetailsLoadStart,
  setPatientDetails,
  setMLDiagnosisProbability,
  patientDetailsLoadError,
  patientDetailsLoadEnd,
  initPatientDetails,
  setIsImageViewed,
  updateStatus,
} from './patientDetails.actions';
import { SYSTEM_STATUSES } from '../../components/helpers/groups';
import {
  getCaseUUIDSelector,
  getUserIdentifier,
} from './patientDetails.selectors';
import {
  getCurrentPhycisianEmail,
  getCurrentPhycisianPermission,
} from '../settings/settings.selectors';
import {
  changePendingStatus,
  changeStatus,
  createTreatmentPlanV3,
  getAnnotation,
  getCasesBanchInfo,
  getDoctorDetails,
  getPendingDescriptions,
  getPatientDetailsTranslated,
  nextAvailableCase,
} from '../../services/main.service';
import { openToast } from '../notifications/notifications.actions';
import {
  isStatusChangeAllowed,
  transformMLDiagnosisProbability,
  transformPendingDescription,
} from '../../helpers/sagas.helper';
import { l } from '../../i18n';
import { getAuditAnnotationsByUuidRequest } from '../auditAnnotations/auditAnnotations.actions';
import { getPatientHistoryDiseases } from '../patientHistory/patientHistory.actions';
import {
  loadingGlobalOff,
  setFailedAvailableCases,
  SETTINGS_LOADING_ACTIONS,
} from '../settings/settings.actions';
import { copyPhoneToClipboard } from '../../helpers/copyTextToClipboard';
import { ROUTES } from '../../helpers/route.helper';

export const HOLD_BATCH_ACTIONS = Object.freeze({
  blockCases: 'BLOCK_CASES_ON _HOLD',
  releaseCases: 'RELEASE_CASES_FROM _HOLD',
});

export const BLOCK_CASES_RESPONSE = Object.freeze({
  casesAreBlocked: 'BLOCKED',
  casesAreUnblocked: 'UNBLOCKED',
  forbiddenToBlock: 'FORBIDDEN_TO_BLOCK',
});

export function* copyPatientId() {
  let userIdentifier = yield select(getUserIdentifier);
  yield copyPhoneToClipboard(userIdentifier);
}

export function* createTreatmentPlanWORK({ payload }) {
  try {
    payload.status = payload?.status || SYSTEM_STATUSES.DIAGNOSED;

    // eslint-disable-next-line
    const { response, error } = yield call(createTreatmentPlanV3, payload);

    if (response?.data === 'success') {
      if (payload.status === SYSTEM_STATUSES.DIAGNOSED) {
        payload.status = SYSTEM_STATUSES.DIAGNOSED_NOTIFIED;
        // eslint-disable-next-line
        const { response, error: errorV33 } = yield call(createTreatmentPlanV3, {
          caseId: payload.caseId,
          form: { notify: true },
          status: payload.status,
        });

        if (errorV33?.message) {
          yield put(createTreatmentPlanFail(errorV33 || 'error'));
          return
        }

      }
      yield put(changeStatusState(payload.caseId, payload.status));
      yield put(createTreatmentPlanSuccess());
      yield put(getAnnotations(payload.caseId));
    } else {
      yield put(createTreatmentPlanFail(response?.err || 'error'));
    }
  } catch (error) {
    console.log('createTreatmentPlanWORK error = ', error);
    yield put(createTreatmentPlanFail(error?.message));
  }
}
function* confirmTER({ payload: { uuid, user } }) {
  yield call(changeStatus, uuid, {
    status: SYSTEM_STATUSES.COMPLETELY_CLOSED,
    updated_by: user,
  });
  yield put(changeStatusState(uuid, SYSTEM_STATUSES.COMPLETELY_CLOSED));
  yield put(createTreatmentPlanSuccess());
}

function* getBanchInfo({ payload: { banch, currentUuid } }) {
  try {
    const array = yield call(getCasesBanchInfo, banch);
    const index = array.findIndex((c) => c.uuid === currentUuid);
    yield put(updateAwaitingCasesArray(array, index >= 0 ? index : 0));
  } catch (e) {}
}

function* watchCopyPatientId() {
  yield takeLatest(PATIENT_DETAILS_TYPES.COPY_PATIENT_ID, copyPatientId);
}

function* watchCreateTreatmentPlan() {
  yield takeLatest(
    PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_START,
    createTreatmentPlanWORK
  );
}

function* updateStatusWork({ payload: { status, uuid } }) {
  try {
    const updated_by = yield select(getCurrentPhycisianEmail);
    const res = yield call(changeStatus, uuid, { updated_by, status });
    if (res) {
      yield put(changeStatusState(uuid, status));
      yield put(getAnnotations(uuid));
    }
  } catch (e) {
    console.log(`update_status failed, check it.`);
  }
}

function* blockCasesBatchWORK(act) {
  const { action, description } = act?.payload || {};
  const isActionBlocking = action === HOLD_BATCH_ACTIONS.blockCases;
  const status = isActionBlocking
    ? SYSTEM_STATUSES.PENDING
    : SYSTEM_STATUSES.IN_PROCESS;

  try {
    const uuid = yield select(getCaseUUIDSelector);
    const physician = yield select(getCurrentPhycisianEmail);
    yield put(blockCasesBatchStart(!isActionBlocking));

    const body = { action, physician, uuid, description };
    const process = yield call(changePendingStatus, body);

    if (process === BLOCK_CASES_RESPONSE.forbiddenToBlock) {
      yield put(openToast(l('casePending.forbiddenToBlock')));
      yield put(blockCasesBatchFail(null, !isActionBlocking));
      return;
    }

    const descriptions = yield call(getPendingDescriptions, { uuid });
    yield put(
      blockCasesBatchSuccess(
        transformPendingDescription(descriptions),
        !isActionBlocking
      )
    );

    yield put(changeStatusState(uuid, status));
  } catch (e) {
    yield put(blockCasesBatchFail(e, !isActionBlocking));
  }
}

function* load_annotations({ payload }) {
  yield put(fetchAnnotationsStart());
  const data = yield call(getAnnotation, payload);
  yield put(setAnotations(data));
}

function* load_patiens_details({ payload: { caseId, lang } }) {
  yield put(patientDetailsLoadStart());

  const user = yield select(getCurrentPhycisianEmail);
  const permission = yield select(getCurrentPhycisianPermission);
  try {
    const data = yield call(getPatientDetailsTranslated, caseId, lang);
    let doctorDataDiagnosedBy;
    if (data?.diagnosed_by && user !== data?.diagnosed_by) {
      const { admin_settings } = yield call(
        getDoctorDetails,
        data?.diagnosed_by
      );
      doctorDataDiagnosedBy = {
        name: {
          en: admin_settings?.en,
          he: admin_settings?.he,
          ru: admin_settings?.ru,
          fr: admin_settings?.fr,
          cn: admin_settings?.cn,
        },
        physicianPrefix: admin_settings.physicianPrefix,
      };
    }
    data.case_uuid = caseId;

    const description = transformPendingDescription(data.pending_description);
    const mlDiagnosisProbability = transformMLDiagnosisProbability(
      data.ml_diagnosis_probability
    );
    const createTreatmentPlanSuccess = [33, 37, 39, 46, 99, 90].includes(
      data.status
    );
    yield put(
      setPatientDetails({
        ...data,
        doctorDataDiagnosedBy,
        description,
        createTreatmentPlanSuccess,
      })
    );

    yield put(setMLDiagnosisProbability(mlDiagnosisProbability));
    yield put(getAuditAnnotationsByUuidRequest(caseId));

    yield put(getAnnotations(caseId));
    yield put(getPatientHistoryDiseases(data.user_identifier, data.user_hash));

    if (
      data.status === SYSTEM_STATUSES.COMPLETED_FOR_DIAGNOSE &&
      isStatusChangeAllowed(permission)
    ) {
      yield put(updateStatus(caseId, SYSTEM_STATUSES.IN_PROCESS));
    }
  } catch (e) {
    yield put(patientDetailsLoadError(e));
    yield put(setFailedAvailableCases(true));
  } finally {
    yield put(patientDetailsLoadEnd());
  }
}

function* getNac({ payload: history }) {
  history.push(ROUTES.PATIENTS_LIST);
  const physician = yield select(getCurrentPhycisianEmail);
  const cases = yield call(nextAvailableCase, physician);
  yield put(initPatientDetails());
  yield put(setIsImageViewed(false));

  if (!Array.isArray(cases) || !cases.length) {
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.NEXT_CASE));
    yield put(setFailedAvailableCases(true));
    return;
  }

  let currentCaseIndex = cases.findIndex(
    (c) =>
      c.status === SYSTEM_STATUSES.IN_PROCESS ||
      c.status === SYSTEM_STATUSES.EDITING
  );
  if (currentCaseIndex === -1) {
    currentCaseIndex = 0;
  }
  yield put(updateAwaitingCasesArray(cases, currentCaseIndex));

  yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.NEXT_CASE));

  const search = new URLSearchParams();
  cases.forEach((c) => {
    search.append('caseId', c.uuid);
  });
  history.replace({
    pathname: `${ROUTES.PATIENTS_LIST}/${cases[currentCaseIndex].uuid}`,
    search: search.toString(),
  });
}

function* watchBlockCasesBatch() {
  yield takeLatest(
    PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_REQUEST,
    blockCasesBatchWORK
  );
}

function* wathUpdateStatus() {
  yield takeLatest(PATIENT_DETAILS_TYPES.UPDATE_STATUS, updateStatusWork);
}

function* watchGetCasesBanchInfo() {
  yield takeLatest(PATIENT_DETAILS_TYPES.GET_CASES_BATCH_INFO, getBanchInfo);
}

function* wathConfirmTER() {
  yield takeLatest(PATIENT_DETAILS_TYPES.CONFIRM_TER, confirmTER);
}
function* watchLoadAnnotations() {
  yield takeLatest(PATIENT_DETAILS_TYPES.GET_ANNOTATIONS, load_annotations);
}

function* watchLoadPatientDetails() {
  yield takeLatest(
    PATIENT_DETAILS_TYPES.GET_PATIENT_DETAILS,
    load_patiens_details
  );
}

function* watchGetNac() {
  yield takeLatest(PATIENT_DETAILS_TYPES.VIEW_NEXT_AVAILABLE_CASE, getNac);
}

const patientDetailsSagas = [
  fork(watchCopyPatientId),
  fork(watchCreateTreatmentPlan),
  fork(watchGetCasesBanchInfo),
  fork(wathConfirmTER),
  fork(wathUpdateStatus),
  fork(watchBlockCasesBatch),
  fork(watchLoadAnnotations),
  fork(watchLoadPatientDetails),
  fork(watchGetNac),
];
export default patientDetailsSagas;
