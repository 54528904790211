import * as ADMIN_TYPES from './admin.types';
export const PHYSICIAN_DEFAULT_NAME_PREFIX = Object.freeze({
  namePrefixKey: 'physicianDrTitle',
  namePrefixValue: 'Dr.',
});

export const updatePhysicianNamePrefix = (
  payload = PHYSICIAN_DEFAULT_NAME_PREFIX
) => ({
  type: ADMIN_TYPES.UPDATE_PHYSICIAN_NAME_PREFIX,
  payload,
});

export const setLoadingActDeact = (isLoading) => ({
  type: ADMIN_TYPES.SET_LOADING_ACT_DEACT,
  payload: isLoading,
});

export const setActivateVacation = (isVacationSet) => ({
  type: ADMIN_TYPES.SET_IS_VACATION_ACTIVATED,
  payload: isVacationSet,
});

export const savePhysicianDetailsRequest = (physicianData) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS,
  payload: { physicianData },
});

export const savePhysicianDetailSuccess = (data) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS_SUCCESS,
  payload: data,
});

export const savePhysicianDetailsFail = (error) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_DETAILS_FAIL,
  error,
});

export const savePhysicianStampRequest = (file, type) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_STAMP,
  payload: { file, type },
});

export const savePhysicianProfileImageRequest = (file, type) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_PROFILE_IMAGE_REQUEST,
  payload: { file, type },
});

export const savePhysicianStampSuccess = (data) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_STAMP_SUCCESS,
  payload: data,
});

export const savePhysicianProfileImageSuccess = (data) => ({
  type: ADMIN_TYPES.savePhysicianProfileImageSuccess,
  payload: data,
});

export const savePhysicianStampFail = (error) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_STAMP_FAIL,
  error,
});

export const savePhysicianProfileImageFail = (error) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_PROFILE_IMAGE_FAIL,
  error,
});

export const getPhysicianStampRequest = (fileName) => ({
  type: ADMIN_TYPES.GET_PHYSICIAN_STAMP_REQUEST,
  payload: { fileName },
});

export const getPhysicianProfileImageRequest = (fileName) => ({
  type: ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_REQUEST,
  payload: { fileName },
});

export const getPhysicianStampSuccess = (link) => ({
  type: ADMIN_TYPES.GET_PHYSICIAN_STAMP_SUCCESS,
  payload: { link },
});

export const getPhysicianProfileImageSuccess = (link) => ({
  type: ADMIN_TYPES.GET_PHYSICIAN_PROFILE_IMAGE_SUCCESS,
  payload: { link },
});

export const getPhysicianStampFail = (error) => ({
  type: ADMIN_TYPES.getPhysicianStampFail,
  error,
});

export const savePhysicianInformationRequest = (isRemoveVacation) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION,
  payload: isRemoveVacation,
});

export const savePhysicianInformationRequestStart = () => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_START,
});

export const savePhysicianInformationSuccess = () => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_SUCCESS,
});

export const savePhysicianInformationFail = (error) => ({
  type: ADMIN_TYPES.SAVE_PHYSICIAN_INFORMATION_FAIL,
  error,
});

export const updateDoctorManagemtByPhysician = (doctorManagement) => ({
  type: ADMIN_TYPES.UPDATE_DOCTOR_MANAGMENT_BY_PHYSICIAN,
  payload: { doctorManagement },
});

export const updateAdminSettingsByPhysicianRequest = (email) => ({
  type: ADMIN_TYPES.UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN,
  payload: email,
});

export const updateCurrentPhysicianVocation = (vacation) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_VACATION_PERIOD,
  payload: vacation,
});

export const updateCurrentPhysicianSignature = (signature) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_SIGNATURE,
  payload: { signature },
});

export const updateCurrentPhysicianPhone = (phone) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_PHONE,
  payload: { phone },
});

export const updateCurrentPhysicianName = ({
  name,
  lang,
  docDetails = null,
}) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_NAME,
  payload: {
    name,
    lang,
    docDetails,
  },
});

export const updateCurrentPhysicianAllNames = (names) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_ALL_NAMES,
  payload: {
    names,
  },
});

export const updatePhysicianPrefix = (physicianPrefix) => ({
  type: ADMIN_TYPES.UPDATE_PHYSICIAN_PREFIX,
  payload: { physicianPrefix },
});

export const updateCurrentPhysician = (user) => ({
  type: ADMIN_TYPES.UPDATE_CURRENT_PHYSICIAN_USER,
  payload: { user },
});

export const updateUserActivity = (isActive) => ({
  type: ADMIN_TYPES.UPDATE_USER_ACTIVITY,
  payload: { isActive },
});

export const resetAdminSettings = () => ({
  type: ADMIN_TYPES.RESET_ADMIN_SETTINGS,
});

export const setAdminSettings = (payload) => ({
  type: ADMIN_TYPES.SET_ADMIN_SETTINGS,
  payload,
});
