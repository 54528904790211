import { fork, put, takeLatest } from 'redux-saga/effects';
import * as PHYSICIANS_TYPES from './physicians.types';
import {
  loadSelectedPhysician,
  setSelectedPhysician,
  setPhysicians,
} from './physicians.actions';
import { getPhysician, getPhysicians } from '../../services/main.service';

function* loadPhysicians({ payload: isFirstLoad }) {
  const physicians = yield getPhysicians();
  if (isFirstLoad) {
    yield put(loadSelectedPhysician(physicians[0].id));
  }
  yield put(setPhysicians(physicians));
}

function* loadCurrentPhysicianWork({ payload: id }) {
  const physician = yield getPhysician(id);
  yield put(setSelectedPhysician(physician));
}

function* wathLoadPhysicians() {
  yield takeLatest(PHYSICIANS_TYPES.LOAD_PHYSICIANS, loadPhysicians);
  yield takeLatest(
    PHYSICIANS_TYPES.LOAD_SELECTED_PHYSICIAN,
    loadCurrentPhysicianWork
  );
}
const physiciansSagas = [fork(wathLoadPhysicians)];

export default physiciansSagas;
