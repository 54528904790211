import * as moment from 'moment';
import { getDir } from '../../helpers/route.helper';
import * as SETTINGS_TYPES from './settings.types';

const initialState = {
  user: null,
  userSettings: null,
  vendor: '',
  loginProcess: false,
  permission: '',
  lightboxShow: false,
  image: null,
  isCurrentAvailableFaild: false,
  isGlobalLoading: false,
  isScreenBlocked: false,
  aiModel: null,
  isValidated: null,
  isValidationCode: null,
  isOnVacation: false,
};

const loadingComponents = [];

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_TYPES.setAiModel:
      return {
        ...state,
        aiModel: action.payload,
      };
    case SETTINGS_TYPES.loadingGlobalOff:
      const index = loadingComponents.indexOf(action.payload);

      if (index !== -1) {
        loadingComponents.splice(index, 1);
      }

      if (!loadingComponents.length) {
        return {
          ...state,
          isGlobalLoading: false,
        };
      }
      return {
        ...state,
      };

    case SETTINGS_TYPES.loadingGlobalOn:
      if (loadingComponents.indexOf(action.payload) === -1) {
        loadingComponents.push(action.payload);
      }

      return {
        ...state,
        isGlobalLoading: true,
      };
    case SETTINGS_TYPES.SET_USER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SETTINGS_TYPES.SET_FAILED_AVAILABLE_CASES:
      return {
        ...state,
        isCurrentAvailableFaild: action.payload,
      };
    case SETTINGS_TYPES.SET_USER_SETTINGS: {
      const userSettings = action.payload;
      const lng = userSettings?.language ?? 'he';
      moment.updateLocale(lng);
      return {
        ...state,
        userSettings: { ...userSettings, dir: getDir(lng) },
      };
    }
    case SETTINGS_TYPES.SET_USER_LANGUAGE: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          language: action.payload,
          dir: getDir(action.payload),
        },
      };
    }
    case SETTINGS_TYPES.SET_LOGIN_PROCESS: {
      return {
        ...state,
        loginProcess: action.payload,
      };
    }
    case SETTINGS_TYPES.OPEN_LIGHTBOX: {
      return {
        ...state,
        lightboxShow: true,
        ...action.payload,
      };
    }
    case SETTINGS_TYPES.CLOSE_LIGHTBOX: {
      return {
        ...state,
        lightboxShow: false,
        images: null,
      };
    }
    case SETTINGS_TYPES.SET_ISVALIDATIONCODE: {
      return {
        ...state,
        isValidationCode: action.payload,
      };
    }
    case SETTINGS_TYPES.UPDATE_IS_ON_VACATION:
      return {
        ...state,
        isOnVacation: action.payload,
      };

    default: {
      return state;
    }
  }
}
