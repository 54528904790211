export const envSettingsTypes = Object.freeze({
  ANALYTICS_URL: 'ANALYTICS_URL',
  TENANT: 'TENANT',
  VERSION: 'VERSION',
});

export const TENANTS = Object.freeze({
  MYSKIN: 'MYSKIN',
  MACCABI: 'MACCABI',
});

export const imagesServicesTypes = Object.freeze({
  AZURE: 'azure',
  GCP: 'gcp',
});
