export const PROJECT_NAMES = {
  myderma: 'myderma',
  skintalks: 'skintalks',
};

export const isSkintalks =
  process.env.REACT_APP_PROJECT_NAME === PROJECT_NAMES.skintalks;

export const isMyderma =
  process.env.REACT_APP_PROJECT_NAME === PROJECT_NAMES.myderma;

export const EDoctorPermissions = Object.freeze({
  readOnly: 'read_only',
  admin: 'admin',
  user: 'user',
  superuser: 'superuser',
});

export const languagesObject = Object.freeze({
  EN: 'en',
  HE: 'he',
  RU: 'ru',
  FR: 'fr',
  CN: 'cn',
});

export const languages = [
  { lang: languagesObject.HE, dir: 'rtl' },
  { lang: languagesObject.EN, dir: 'ltr' },
  { lang: languagesObject.RU, dir: 'ltr' },
  { lang: languagesObject.FR, dir: 'ltr' },
  { lang: languagesObject.CN, dir: 'ltr' },
];

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
