import * as NOTIFICATIONS_TYPES from './notifications.types';

export const SYSTEM_ALLOWED_NOTIFICATIONS = Object.freeze({
  LOGIN_NOTIFICATION: 'LOGIN_NOTIFICATION',
  LOGOUT_NOTIFICATION: 'LOGOUT_NOTIFICATION',
  ADD_DIAGNOSE_NOTIFICATION: 'ADD_DIAGNOSE_NOTIFICATION',
  ADD_DIAGNOSE_NOTIFY_NOTIFICATION: 'ADD_DIAGNOSE_NOTIFY_NOTIFICATION',
  CANCEL_DIAGNOSE_NOTIFICATION: 'CANCEL_DIAGNOSE_NOTIFICATION',
  NOTIFICATION_NOTIFY_PATIENT: 'NOTIFICATION_NOTIFY_PATIENT',
  CLEAR_HISTORY: 'CLEAR_HISTORY',
  SET_HISTORY_CALLBACK: 'SET_HISTORY_CALLBACK_ACTION',
});

export const openToast = (message, status = 'success') => ({
  type: NOTIFICATIONS_TYPES.OPEN_TOAST,
  payload: { status, message },
});

export const cloaseToast = () => ({
  type: NOTIFICATIONS_TYPES.CLOSE_TOAST,
});
