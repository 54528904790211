export const initFilterSettings = 'INIT_FILTER_SETTINGS';
export const initFilterSettingsStart = 'INIT_FILTER_SETTINGS_START';
export const getPatientsStart = 'PATIENT_LIST_GET_PATIENTS_START';
export const getPatientsSuccess = 'PATIENT_LIST_GET_PATIENTS_SUCCESS';
export const getPatientsFail = 'PATIENT_LIST_GET_PATIENTS_FAIL';
export const getPatients = 'PATIENT_LIST_GET_PATIENTS';
export const unsubscrbeGetPatients = 'UNSUBSCRIBE_PATIENT_LIST_GET_PATIENTS';
export const subscrbeGetPatients = 'SUBSCRIBE_PATIENT_LIST_GET_PATIENTS';
export const updateGlobalSearch = 'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST';
export const updateGlobalSearchStart =
  'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_START';
export const updateGlobalSearchSuccess =
  'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_SUCCESS';
export const updateGlobalSearchFail =
  'PATIENT_LIST_UPDATE_GLOBAL_SEARCH_REQUEST_FAIL';
export const updatePageLimit = 'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST';
export const updatePageLimitStart =
  'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST_START';
export const updatePageLimitFail =
  'PATIENT_LIST_UPDATE_PAGE_LIMIT_REQUEST_FAIL';
export const updatePageOffset = 'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST';
export const updatePageOffsetStart =
  'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST_START';
export const updatePageOffsetFail =
  'PATIENT_LIST_UPDATE_PAGE_OFFSET_REQUEST_FAIL';
export const updatePatientsCaseId = 'PATIENT_LIST_UPDATE_CASE_ID_REQUEST';
export const updatePatientsCaseIdStart =
  'PATIENT_LIST_UPDATE_CASE_ID_REQUEST_START';
export const updatePatientsCaseIdFail =
  'PATIENT_LIST_UPDATE_CASE_ID_REQUEST_FAIL';
export const updatePatientsId = 'PATIENT_LIST_UPDATE_ID_REQUEST';
export const updatePatientsIdStart = 'PATIENT_LIST_UPDATE_ID_REQUEST_START';
export const updatePatientsIdFail = 'PATIENT_LIST_UPDATE_ID_REQUEST_FAIL';
export const updateCreatedAtValue =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST';
export const updateCreatedAtValueStart =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST_START';
export const updateCreatedAtValueFail =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_REQUEST_FAIL';
export const updateCreatedAtDateRangeValue =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST';
export const updateCreatedAtValueDateRangeStart =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST_START';
export const updateCreatedAtValueDateRangeFail =
  'PATIENT_LIST_UPDATE_CREATED_AT_VALUE_DATE_RANGE_REQUEST_FAIL';
export const updateAgeValue = 'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST';
export const updateAgeValueStart =
  'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST_START';
export const updateAgeValueFail = 'PATIENT_LIST_UPDATE_AGE_VALUE_REQUEST_FAIL';
export const updateGenderValue = 'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST';
export const updateGenderValueStart =
  'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST_START';
export const updateGenderValueFail =
  'PATIENT_LIST_UPDATE_GENDER_VALUE_REQUEST_FAIL';
export const updateEfficiencyReportValue =
  'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST';
export const updateEfficiencyReportValueStart =
  'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST_START';
export const updateEfficiencyReportValueFail =
  'PATIENT_LIST_UPDATE_EFFICIENCY_REPORT_VALUE_REQUEST_FAIL';
export const updateStatusValue = 'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST';
export const updateStatusValueStart =
  'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST_START';
export const updateStatusValueFail =
  'PATIENT_LIST_UPDATE_STATUS_VALUE_REQUEST_FAIL';
export const updateDiagnosedByValue =
  'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST';
export const updateDiagnosedByValueStart =
  'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST_START';
export const updateDiagnosedByValueFail =
  'PATIENT_LIST_UPDATE_DIAGNOSED_BY_VALUE_REQUEST_FAIL';
export const updateDiagnosedAtValue =
  'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST';
export const updateDiagnosedAtValueStart =
  'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST_START';
export const updateDiagnosedAtValueFail =
  'PATIENT_LIST_UPDATE_DIAGNOSED_AT_VALUE_REQUEST_FAIL';
export const resetAllFilters = 'PATIENT_LIST_RESET_ALL_FILTERS';
export const resetAllFiltersStart = 'PATIENT_LIST_RESET_ALL_FILTERS_START';
export const updateCreatedAtOrder = 'PATIENT_LIST_UPDATE_CREATED_AT_ORDER';
export const updateCreatedAtOrderStart =
  'PATIENT_LIST_UPDATE_CREATED_AT_ORDER_START';
export const updatePageNumber = 'PATIENT_LIST_UPDATE_PAGE_NUMBER';
export const updatePageNumberStart = 'PATIENT_LIST_UPDATE_PAGE_NUMBER_START';
