import * as AUDIT_ANNOTATIONS_TYPES from './auditAnnotations.types';

const initialState = {
  isLoadingAuditAnnotations: false,
  aAnnotations: [],
  createdAAnnotation: {},
};

export default function auditAnnotationsReducer(state = initialState, action) {
  switch (action.type) {
    case AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST:
      return {
        ...state,
        isLoadingAuditAnnotations: true,
      };
    case AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_SUCCESS:
      return {
        ...state,
        isLoadingAuditAnnotations: false,
        aAnnotations: action.payload.annotations,
      };
    case AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST_FAIL:
      return {
        ...state,
        isLoadingAuditAnnotations: false,
      };
    case AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_REQUEST:
      return {
        ...state,
        isLoadingAuditAnnotations: true,
      };
    case AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_SUCCESS:
      return {
        ...state,
        isLoadingAuditAnnotations: false,
        createdAAnnotation: action.payload.annotation,
      };
    case AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_FAIL:
      return {
        ...state,
        isLoadingAuditAnnotations: false,
      };
    default:
      return state;
  }
}
