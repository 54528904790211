import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {history} from './store'
import { ConnectedRouter } from 'connected-react-router'
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css';
import './index.css';
import App from './App';
import './i18n';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import store from './store'
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';

import 'typeface-roboto';
import './assets/style.css';


import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister();