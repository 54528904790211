import { fork, put, takeLatest, call, delay } from 'redux-saga/effects';
import * as AUTH_TYPES from './auth.types';

import {
  validateReceivedRegistrationParametersStart,
  validateReceivedRegistrationParametersSuccess,
  validateReceivedRegistrationParametersFail,
  savePhysicianPasswordStart,
  savePhysicianPasswordSuccess,
  savePhysicianPasswordFail,
  savePhysicianPasswordInvalid,
  RESET_PASSWORD_ACTIONS,
  createNewPasswordStart,
  createNewPasswordSuccess,
  createNewPasswordFail,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
} from '../../store/auth/auth.actions';
import {
  AUTHENTICATED_RESPONSE,
  INVALID_PASSWORD,
} from '../../components/Login/constants';
import {
  validateCode,
  savePhysicianPassword,
} from '../../services/auth.service';
import {
  createNewPhysicianPassword,
  resetPhysicianPassword,
} from '../../services/auth.service';
import { openToast } from '../notifications/notifications.actions';
import { resetAdminSettings } from '../../store/admin/admin.actions';
import { l } from '../../i18n';
import {
  setIsValidationCode,
  setSettingsUser,
} from '../../store/settings/settings.actions';

export const SYSTEM_REGISTRATION_RESPONSE = Object.freeze({
  codeAuthorized: 'CODE_AUTHORIZED',
  codeNotAuthorized: 'CODE_NOT_AUTHORIZED',
});

function* registrationValidationParametersWORK(action) {
  try {
    const { email, code } = action.payload || {};
    yield put(validateReceivedRegistrationParametersStart());
    const authentication = yield call(validateCode, email, code);
    if (authentication === SYSTEM_REGISTRATION_RESPONSE.codeAuthorized) {
      yield put(validateReceivedRegistrationParametersSuccess());
    } else {
      yield put(
        validateReceivedRegistrationParametersFail(new Error('anuthorized'))
      );
    }
  } catch (e) {
    yield put(validateReceivedRegistrationParametersFail(e));
  }
}

function* saveRegistrationAssignPasswordWORK(action) {
  const { email, password, history } = action.payload || {};
  try {
    yield put(savePhysicianPasswordStart());
    const authentication = yield call(savePhysicianPassword, email, password);
    if (
      INVALID_PASSWORD === authentication ||
      INVALID_PASSWORD === authentication.status
    ) {
      yield put(savePhysicianPasswordInvalid());
    } else if (
      authentication === AUTHENTICATED_RESPONSE ||
      authentication.status === AUTHENTICATED_RESPONSE
    ) {
      yield put(savePhysicianPasswordSuccess());
      history.push(`/login?email=${email}&password=${password}`);
    } else {
      yield put(savePhysicianPasswordFail(new Error(authentication)));
      history.push(`/login`);
    }
  } catch (e) {
    yield put(savePhysicianPasswordFail(e));
    history.push(`/login`);
  }
}

function* createNewPasswordProcessWORK({
  payload: { validation_code, new_password, history },
}) {
  try {
    yield put(createNewPasswordStart());
    const result = yield call(createNewPhysicianPassword, {
      validation_code,
      new_password,
      email: new URLSearchParams(history.location.search).get('email'),
    });
    yield put(createNewPasswordSuccess());
    yield put(setIsValidationCode(false));
    if (!result.is_validation_code && !result.is_validated) {
      history.push('/user-management-validate');
      return;
    }
    history.push('/login');
  } catch (e) {
    yield put(createNewPasswordFail(e?.response?.data));
  }
}

function* resetPasswordProcessWORK(action) {
  try {
    const { email, history } = action.payload;
    yield put(resetPasswordStart());
    const actionResponse = yield call(resetPhysicianPassword, email);
    yield put(resetPasswordSuccess(actionResponse));
    if (actionResponse === RESET_PASSWORD_ACTIONS.SENT_CONFIRMED && history) {
      yield delay(10000);
      history.push('/login');
    }
  } catch (e) {
    yield put(resetPasswordFail(e));
  }
}

function* logOut() {
  yield put(
    setSettingsUser({
      user: '',
      vendor: '',
      permission: '',
      admin_settings: {},
    })
  );
  yield put(resetAdminSettings());
  localStorage.removeItem('access_token');
  yield put(openToast(l('registration.expiredSession'), 'info'));
}

export function* watchSaveRegistrationAssignPassword() {
  yield takeLatest(
    AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD,
    saveRegistrationAssignPasswordWORK
  );
}

export function* watchRegistrationValidationParameters() {
  yield takeLatest(
    AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS,
    registrationValidationParametersWORK
  );
}

function* watchCreateNewPasswordProcess() {
  yield takeLatest(
    AUTH_TYPES.CREATE_NEW_PASSWORD_REQUEST,
    createNewPasswordProcessWORK
  );
}

function* watchResetPasswordProcess() {
  yield takeLatest(AUTH_TYPES.RESET_PASSWORD_REQUEST, resetPasswordProcessWORK);
}

function* watchLogOut() {
  yield takeLatest(AUTH_TYPES.LOG_OUT, logOut);
}

const authSagas = [
  fork(watchCreateNewPasswordProcess),
  fork(watchResetPasswordProcess),
  fork(watchRegistrationValidationParameters),
  fork(watchSaveRegistrationAssignPassword),
  fork(watchLogOut),
];

export default authSagas;
