import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as PATIENT_HISTORY_TYPES from './patientHistory.types';

import {
  getPatientHistoryDiseasesFail,
  getPatientHistoryDiseasesStart,
  getPatientHistoryDiseasesSuccess,
} from './patientHistory.actions';
import { getPatientHistory } from '../../services/main.service';
import { getTenant } from '../auth/auth.selectors';
import { TENANTS } from '../../helpers/auth.helper';

function* getPatientDiseasesHistory({
  payload: { user_identifier, user_hash },
}) {
  try {
    yield put(getPatientHistoryDiseasesStart());
    const tenant = yield select(getTenant);
    const identifier = tenant === TENANTS.MYSKIN ? user_hash : user_identifier;

    const history = yield call(getPatientHistory, identifier);
    yield put(getPatientHistoryDiseasesSuccess(history));
  } catch (error) {
    if (error instanceof Error) {
      yield put(getPatientHistoryDiseasesFail(error));
    }
  }
}

function* watchGetPatientHistory() {
  yield takeLatest(
    PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES,
    getPatientDiseasesHistory
  );
}
const patientHistorySagas = [fork(watchGetPatientHistory)];

export default patientHistorySagas;
