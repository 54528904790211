import React from 'react';
import { Button } from '@material-ui/core';
import { useStyles } from './styles';

const CustomButton = ({
  type,
  fullWidth = false,
  variant,
  color,
  disabled,
  className,
  onClick = () => {},
  children,
  disabledColorText = '#fff',
  ...args
}) => {
  const s = useStyles({ disabledColorText, color });

  return (
    <Button
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      disabled={disabled}
      disableRipple={true}
      className={`${className} ${s.button}`}
      onClick={onClick}
      {...args}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
