import { languages } from '../../helpers/constants';
import { getPrefixForLang } from '../../helpers/getPrefixForLang';
import { getLanguage } from '../settings/settings.selectors';

export const getDoctorInfo = (state) => {
  const {
    degree,
    license,
    expert_license,
    physician_details,
    stamp_blob_link,
  } = state.admin;
  return {
    degree,
    license,
    expert_license,
    physician_details,
    stamp_blob_link,
  };
};
export const getClinicDetails = ({
  admin: {
    clinic_phone,
    clinic_address,
    clinic_address_second_part,
    clinic_city,
    clinic_country,
    clinic_zip,
    clinic_region,
  },
}) => ({
  clinic_phone,
  clinic_address,
  clinic_address_second_part,
  clinic_city,
  clinic_country,
  clinic_zip,
  clinic_region,
});
export const getAiNetworks = ({ admin }) => admin.ai_networks;
export const getCurrentUser = ({ admin }) => admin.user;
export const getCurrentAdmin = ({ admin }) => admin.admin;
export const getCurrentUserName = (state) => {
  const lang = getLanguage(state);
  return state.admin[lang]?.name || '';
};
export const getCurrentUserNameAndPrefix = (state) => {
  const lang = getLanguage(state);
  return state.admin && state.admin[lang]
    ? { prefix: state.admin.physicianPrefix, name: state.admin[lang].name }
    : { prefix: '', name: '' };
};
export const getUserNameInAllLanguages = (state) => {
  let allNames = {};
  for (let i = 0; i < languages.length; i++) {
    let lang = languages[i].lang;
    if (state.admin && state.admin[lang]) {
      allNames[lang] = state.admin[lang].name;
      if (state.admin.name) {
        allNames['general'] = state.admin.name;
      }
    }
  }

  if (state.admin && typeof state.admin.name == 'string') {
    allNames.name = state.admin.name;
  }

  return allNames;
};
export const getCurrentVacation = ({ admin }) => admin.vacation;
export const getDoctorManagement = ({ admin }) => admin.doctorManagement || {};
export const getCurrentUserPhone = ({ admin }) => admin.phone;
export const getCurrentSignature = ({ admin }) => admin.signature;
export const getCurrentStamp = ({ admin }) => admin.stamp;
export const getCurrentStampBlobLink = ({ admin }) => admin.stamp_blob_link;
export const getCurrentProfileImage = ({ admin }) => admin.profile_image;
export const getCurrentProfileImageBlobLink = ({ admin }) =>
  admin.profile_image_blob_link;
export const getIsStampLoading = ({ admin }) => admin.isStampLoading;
export const getIsProfileImageLoading = ({ admin }) =>
  admin.isProfileImageLoading;
export const getClinicPhone = ({ admin }) => admin.clinic_phone;
export const getClinicAddress = ({ admin }) => admin.clinic_address;
export const getIsCurrentUserActive = ({ admin }) => admin.isActive;
export const getCurrentUserPermission = ({ admin }) => admin.permission;
export const getCurrentUserDegree = ({ admin }) => admin.degree;
export const getCurrentUserLicense = ({ admin }) => admin.license;
export const getCurrentUserExpertLicense = ({ admin }) => admin.expert_license;
export const getCurrentUserDetails = ({ admin }) => admin.physician_details;
export const getIsAdminChangesLoading = ({ admin }) =>
  admin.isAdminChangesLoading;
export const getIsVacationSet = ({ admin }) => admin.isVacationSet;
export const getPhysicianPrefix = ({ admin }) => admin.physicianPrefix;
export const getPhysicianPrefixByLang = (state) => {
  const res = state.admin?.physicianPrefix
    ? getPrefixForLang(
        state.admin.physicianPrefix,
        state.settings.userSettings.language
      )
    : '';
  return res;
};
export const getLoadingActDeact = ({ admin }) => admin.loadingActDeact;

export const getClinicAddressSecondPart = ({ admin }) =>
  admin.clinic_address_second_part;
export const getClinicCity = ({ admin }) => admin.clinic_city;
export const getClinicRegion = ({ admin }) => admin.clinic_region;
export const getClinicCountry = ({ admin }) => admin.clinic_country;
export const getClinicZip = ({ admin }) => admin.clinic_zip;
export const getClinicLinkFaceBook = ({ admin }) => admin.clinic_linkFaceBook;
export const getClinicLinkInstagram = ({ admin }) => admin.clinic_linkInstagram;
export const getClinicLinkLinkedin = ({ admin }) => admin.clinic_linkLinkedin;
export const getClinicLinkUrl = ({ admin }) => admin.clinic_linkUrl;
export const getIsSaveDetailsLoading = ({ admin }) =>
  admin.isSaveDetailsLoading;

export const fetchImagePermission = ({ admin, settings: { user } }) => {
  const management = admin.doctorManagement || {};
  const adminSettings =
    user && management[user] && management[user].admin_settings;
  try {
    if (adminSettings) {
      return adminSettings.showImages;
    } else {
      return false;
    }
  } catch (e) {
    console.log(`cannot fetch admin settings showImages.`);
    return false;
  }
};
