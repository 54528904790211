import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoginHeader from '../shared/LoginHeader';
import * as serverAuth from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { setAdminSettings } from '../../store/admin/admin.actions';
import { DEFAULT_VACATION } from '../../store/admin/admin.reducer';
import Copyright from '../shared/CopyRight';
import { useLocation } from 'react-router-dom';
import { setEnvSettings } from '../../store/auth/auth.actions';
import Button from '../shared/Button';
import {
  BLOCKED_NOT_ACTIVE,
  BLOCKED_ON_VACATION,
  TOO_MANY_REQUESTS_RESPONSE,
} from './constants';
import { HeaderCommon } from '../Header/HeaderCommon';
import {
  setSettingsUser,
  setLoginProcess,
  setUserSetings,
} from '../../store/settings/settings.actions';
import {
  Content,
  Footer,
  FormActions,
  FormErrorText,
  LoginInput,
  ResetPasswordButton,
  StyledForm,
} from './styles';
import ResetPasswordDialog from './ResetPasswordDialog';
import { EMAIL_REGEX, isSkintalks } from '../../helpers/constants';
import { getLoadDiseasesRequest } from '../../store/diseases/diseases.actions';
import { changeLng } from '../../helpers/translations';

const LoginPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [isShowError, setIsShowError] = useState(false);

  const [isResetOpen, setIsResetOpen] = useState(false);

  const [formState, setFormState] = useState({
    error: '',
    fields: {
      email: { value: query.get('email') || '', error: '' },
      password: { value: query.get('password') || '', error: '' },
    },
  });

  useEffect(() => {
    serverAuth.getEnvSettings().then(async (envSettings) => {
      dispatch(setEnvSettings(envSettings));
    });
  }, [dispatch]);

  const validateInput = (name, value) => {
    if (name === 'email') {
      if (!value) {
        return '*Email is required';
      }
      const isMail = EMAIL_REGEX.test(value);
      if (!isMail) {
        return '*Incorrect email format';
      }
    }
    if (name === 'password') {
      if (!value) {
        return '*Password is required';
      }
      const isEnoughLength = value.length >= 3;
      if (!isEnoughLength) {
        return '*Password must have a minimum 3 characters';
      }
    }
  };

  const setloginProcessError = (err) => {
    if (!err) {
      return;
    }

    let messageLocation = 'loginModal.error';
    switch (err) {
      case BLOCKED_NOT_ACTIVE:
        messageLocation = 'loginModal.physicianBlocked';
        break;
      case BLOCKED_ON_VACATION:
        messageLocation = 'loginModal.physicianVacation';
        break;
      case TOO_MANY_REQUESTS_RESPONSE:
        messageLocation = 'loginModal.errorTooManyRequest';
        break;
      default:
        messageLocation = 'loginModal.error';
    }

    setFormState((prev) => ({ ...prev, error: t(messageLocation) }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(formState.fields).some((f) => f.error)) {
      setIsShowError(true);
      return;
    }
    dispatch(setLoginProcess(true));
    try {
      const userData = await serverAuth.login({
        email: formState.fields.email.value,
        password: formState.fields.password.value,
      });

      if (userData?.doctor_email && userData?.permission === 'superuser') {
        props.handleJiraAfterLogin(userData.doctor_email);
      }
      userData.isValidationCode = userData.is_validation_code;
      userData.isValidated = userData.is_validated;
      const language = userData.settings?.language || 'he';
      const newDirection = language === 'he' ? 'rtl' : 'ltr';
      const bodyElem = document.getElementById('lang_direction');
      bodyElem.setAttribute('dir', newDirection);
      localStorage.setItem('access_token', userData.token);
      dispatch(
        setSettingsUser({
          ...userData,
          user: userData.doctor_email,
          vendor: userData.vendor_id,
          permission: userData.permission,
          token: null,
        })
      );
      let isVacationSet = false;
      let vacation = DEFAULT_VACATION;
      if (userData.admin_settings && userData.admin_settings.vacation) {
        const defDate = new Date().toISOString();
        const { start_vacation = defDate, end_vacation = defDate } =
          userData.admin_settings.vacation || {};
        isVacationSet = true;
        vacation = {
          start_vacation,
          end_vacation,
        };
      }

      dispatch(
        setAdminSettings({
          adminSettings: userData.admin_settings,
          admin: userData.doctor_email,
          user: userData.doctor_email,
          is_active: userData.is_active,
          is_validated: userData.is_validated,
          permission: userData.permission,
          vacation,
          isVacationSet,
        })
      );

      dispatch(setUserSetings(userData.settings));
      changeLng(language);
      dispatch(getLoadDiseasesRequest(userData.vendor_id));
    } catch (e) {
      const message = e?.response?.data?.message;
      setloginProcessError(message);
      dispatch(setLoginProcess(false));
    }
  };

  const toggleResetPassword = () => {
    setIsResetOpen((prev) => !prev);
  };

  const hangleChangeValue = ({ target: { name, value } }) => {
    const error = validateInput(name, value);
    setFormState((prev) => ({
      ...prev,
      error: '',
      fields: { ...prev.fields, [name]: { value, error } },
    }));
  };

  const inputs = [
    {
      name: 'email',
      label: 'loginModal.user',
      type: 'email',
    },
    {
      name: 'password',
      label: 'loginModal.password',
      type: 'password',
    },
  ];

  const titleKey = isSkintalks
    ? 'loginModal.titleSkinTalks'
    : 'loginModal.title';

  return (
    <>
      <HeaderCommon />
      <Content>
        <LoginHeader title={titleKey} />
        <StyledForm onSubmit={handleSubmit}>
          {inputs.map(({ name, label, type }) => (
            <LoginInput
              key={name}
              name={name}
              label={label}
              value={formState.fields[name].value}
              type={type}
              onChangeHandler={hangleChangeValue}
              helperText={(isShowError && formState.fields[name].error) || ''}
              error={!!formState.fields[name].error}
              variant="standard"
              isNoLabel
            />
          ))}
          <FormActions>
            <Button type="submit">{t('loginModal.btnLogin')}</Button>
            <ResetPasswordButton
              variant="text"
              type="button"
              onClick={toggleResetPassword}
            >
              {t('forgotPassword.modalButton')}
            </ResetPasswordButton>
          </FormActions>
          <FormErrorText>{formState.error}</FormErrorText>
        </StyledForm>
      </Content>
      <Footer>
        <Copyright />
      </Footer>
      <ResetPasswordDialog open={isResetOpen} onClose={toggleResetPassword} />
    </>
  );
};

export default LoginPage;
