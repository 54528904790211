import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notificationsToastSelector } from '../../store/notifications/notifications.selectors';
import { getLanguage } from '../../store/settings/settings.selectors';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { cloaseToast } from '../../store/notifications/notifications.actions';

const ToastNotification = () => {
  const { open, message, status } = useSelector(notificationsToastSelector);
  const language = useSelector(getLanguage);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(cloaseToast());
  };

  return (
    <div className="text-center">
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: language === 'he' ? 'left' : 'right',
        }}
        style={{ minWidth: '650px' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        children={
          <Alert
            severity="error"
            style={{ minWidth: '650px' }}
            className="alerts-alternate mb-4"
            color={status ? status : 'error'}
          >
            <div className="d-flex align-items-center align-content-start">
              <span>{message}</span>
            </div>
          </Alert>
        }
      />
    </div>
  );
};

export default ToastNotification;
