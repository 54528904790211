import React from 'react';
import { StyledImg } from './styles';
import myDermaLogo from '../../../assets/svg/myDermaLogo.svg';
import skinTalksLogo from '../../../assets/skinTalksLogo.png';
import { isSkintalks } from '../../../helpers/constants';

const Logo = () => {
  const src = isSkintalks ? skinTalksLogo : myDermaLogo;
  return <StyledImg src={src} alt="logo" />;
};

export default Logo;
