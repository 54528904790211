import * as PATIENT_DETAILS_TYPES from './patientDetails.types';

export const copyPatientId = () => ({
  type: PATIENT_DETAILS_TYPES.COPY_PATIENT_ID,
});

export const updateStatus = (uuid, status) => ({
  type: PATIENT_DETAILS_TYPES.UPDATE_STATUS,
  payload: { uuid, status },
});

export const getPatientDetails = (caseId, lang) => ({
  type: PATIENT_DETAILS_TYPES.GET_PATIENT_DETAILS,
  payload: {
    caseId,
    lang,
  },
});

export const setIsImageViewed = (isViewed) => ({
  type: PATIENT_DETAILS_TYPES.SET_IS_IMAGE_VIEWED,
  payload: isViewed,
});

export const setAnotations = (annotations) => ({
  type: PATIENT_DETAILS_TYPES.SET_ANNOTATIONS,
  payload: annotations,
});

export const getAnnotations = (uuid) => ({
  type: PATIENT_DETAILS_TYPES.GET_ANNOTATIONS,
  payload: uuid,
});

export const fetchAnnotationsStart = () => ({
  type: PATIENT_DETAILS_TYPES.FETCH_ANNOTATIONS_START,
});

export const patientDetailsLoadStart = () => ({
  type: PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_START,
});

export const patientDetailsLoadError = (error) => ({
  type: PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_END_ERROR,
  payload: error,
});

export const patientDetailsLoadEnd = () => ({
  type: PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_END,
});

export const setPatientDetails = (details) => ({
  type: PATIENT_DETAILS_TYPES.SET_PATIENT_DETAILS,
  payload: details,
});

export const changeStatusState = (uuid, status) => ({
  type: PATIENT_DETAILS_TYPES.CHANGE_STATUS_STATE,
  payload: { uuid, status },
});

export const fillAwaitingCasesArray = (array) => ({
  type: PATIENT_DETAILS_TYPES.FILL_AWAITING_CASES_ARRAY,
  payload: array,
});

export const updateCurrentCaseIndex = (index) => ({
  type: PATIENT_DETAILS_TYPES.UPDATE_CURRENT_CASE_INDEX,
  payload: index,
});

export const updateAwaitingCasesArray = (array, index) => ({
  type: PATIENT_DETAILS_TYPES.UPDATE_AWAITING_CASES_ARRAY,
  payload: { array, index },
});

export const initPatientDetails = () => ({
  type: PATIENT_DETAILS_TYPES.INIT_PATIENT_DETAILS,
});

export const createTreatmentPlanStart = (payload) => {
  return {
    type: PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_START,
    payload,
  };
};
export const createTreatmentPlanSuccess = (payload) => {
  return {
    type: PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_SUCCESS,
    payload,
  };
};
export const createTreatmentPlanFail = (payload) => {
  return {
    type: PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_FAIL,
    payload,
  };
};
export const createTreatmentPlanReset = (payload) => {
  return {
    type: PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_RESET,
    payload,
  };
};

export const confirmTER = (uuid, user) => ({
  type: PATIENT_DETAILS_TYPES.CONFIRM_TER,
  payload: { uuid, user },
});

export const getCasesBatchInfo = (banch, currentUuid) => ({
  type: PATIENT_DETAILS_TYPES.GET_CASES_BATCH_INFO,
  payload: { banch, currentUuid },
});

export const setMessagesToPatientDialog = (open, title) => ({
  type: PATIENT_DETAILS_TYPES.SET_MESSAGES_TO_PATIENT_DIALOG,
  payload: { title, open },
});

export const blockCasesBatchRequest = (action, description) => ({
  type: PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_REQUEST,
  payload: { action, description },
});

export const blockCasesBatchStart = (unblock) => ({
  type: unblock
    ? PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_START
    : PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_START,
});

export const blockCasesBatchSuccess = (descriptions, unblock) => ({
  type: unblock
    ? PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_SUCCESS
    : PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_SUCCESS,
  payload: descriptions,
});

export const blockCasesBatchFail = (error, unblock) => ({
  type: unblock
    ? PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_FAIL
    : PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_FAIL,
  payload: error,
});

export const updateIsImagesLoading = (isLoad) => ({
  type: PATIENT_DETAILS_TYPES.SET_IMAGES_LOADING,
  payload: isLoad,
});

export const setMLDiagnosisProbability = (probabilities) => ({
  type: PATIENT_DETAILS_TYPES.SET_ML_DIAGNOSIS_PROBABILITY,
  payload: probabilities || null,
});

export const viewNextAvailableCase = (history) => ({
  type: PATIENT_DETAILS_TYPES.VIEW_NEXT_AVAILABLE_CASE,
  payload: history,
});
