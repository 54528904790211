import * as ANALYTIC_TYPES from './analytic.types';

export const subscribeNACAnalyticRequest = () => ({
  type: ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_REQUEST,
  payload: {
    signature: true,
  },
});

export const subscribeNACAnalyticSuccess = (payload) => ({
  type: ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_SUCCESS,
  payload,
});

export const subscriNACAnalyticStart = () => ({
  type: ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_START,
});
