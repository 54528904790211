import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '../store/auth/auth.actions';
import { decode } from 'jsonwebtoken';

export const useAutoLogout = () => {
  const timer = useRef();
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(logOut()), [dispatch]);
  const timeChecker = useCallback(() => {
    try {
      const access_token = localStorage.getItem('access_token');
      const token = decode(access_token);
      const delay = token.exp * 1000 - Date.now();
      if (delay < 0) logout();
      timer.current = setTimeout(() => {
        logout();
      }, delay);
    } catch (error) {
      logout();
    }
  }, [logout]);

  useEffect(() => {
    timeChecker();
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [timeChecker]);
};
