import * as AUTH_TYPES from './auth.types';

export const RESET_PASSWORD_ACTIONS = Object.freeze({
  SENT_CONFIRMED: 'SENT_CONFIRMED',
  DENIED: 'DENIED',
});

export const setEnvSettings = (envSettings) => ({
  type: AUTH_TYPES.SET_ENV_SETTINGS,
  payload: envSettings,
});

export const createNewPasswordRequest = ({
  validation_code,
  new_password,
  history,
}) => ({
  type: AUTH_TYPES.CREATE_NEW_PASSWORD_REQUEST,
  payload: { validation_code, new_password, history },
});

export const createNewPasswordStart = () => ({
  type: AUTH_TYPES.CREATE_NEW_PASSWORD_START,
});

export const createNewPasswordSuccess = () => ({
  type: AUTH_TYPES.CREATE_NEW_PASSWORD_SUCCESS,
});

export const createNewPasswordFail = (error) => ({
  type: AUTH_TYPES.CREATE_NEW_PASSWORD_FAIL,
  payload: { error },
});

export const resetCreateNewPasswordError = () => ({
  type: AUTH_TYPES.RESET_CREATE_NEW_PASSWORD_ERROR,
});

export const resetPasswordRequest = (email, history) => ({
  type: AUTH_TYPES.RESET_PASSWORD_REQUEST,
  payload: { email, history },
});

export const resetPasswordStart = () => ({
  type: AUTH_TYPES.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = (action) => ({
  type: AUTH_TYPES.RESET_PASSWORD_SUCCESS,
  payload: { action },
});

export const resetPasswordFail = (error) => ({
  type: AUTH_TYPES.RESET_PASSWORD_FAIL,
  payload: { error },
});

export const savePhysicianPassword = (email, password, history) => ({
  type: AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD,
  payload: { password, email, history },
});

export const savePhysicianPasswordStart = () => ({
  type: AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_START,
});

export const savePhysicianPasswordSuccess = () => ({
  type: AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_SUCCESS,
});

export const savePhysicianPasswordInvalid = () => ({
  type: AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_INVALID,
});

export const savePhysicianPasswordFail = (error) => ({
  type: AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_FAIL,
  payload: { error },
});

export const validateReceivedRegistrationParameters = (email, code) => ({
  type: AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS,
  payload: { email, code },
});
export const validateReceivedRegistrationParametersStart = () => ({
  type: AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_START,
});
export const validateReceivedRegistrationParametersSuccess = () => ({
  type: AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_SUCCESS,
});
export const validateReceivedRegistrationParametersFail = (error) => ({
  type: AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_FAIL,
  payload: { error },
});

export const logOut = () => ({
  type: AUTH_TYPES.LOG_OUT,
});
