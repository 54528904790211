import React from 'react';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CustomInput = ({
    label,
    name,
    onChangeHandler,
    rows,
    maxRows,
    type,
    value,
    helperText,
    InputProps,
    error = false,
    autoFocus = false,
    disabled = false,
    margin = "dense",
    multiline = false,
    variant = "outlined",
    isNoLabel = false,
    onKeyPressHandler = () => {},
    ...args
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                required
                fullWidth
                error={error}
                autoFocus={autoFocus}
                type={type}
                variant={variant}
                margin={margin}
                id={name}
                name={name}
                autoComplete={name}
                multiline={multiline}
                {...(multiline && rows && { rows })}
                {...(multiline && maxRows && { maxRows })}
                label={!isNoLabel && t(`${label}`)}
                placeholder={t(`${label}`)}
                value={value || ''}
                onChange={onChangeHandler}
                onKeyPress={e => e.charCode === 13 && onKeyPressHandler()}
                disabled={disabled}
                {...(!!helperText && { helperText })}
                {...(!!InputProps && { InputProps })}
                {...args}
            />
        </>
    );
};

export default CustomInput;