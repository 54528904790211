import * as ANALYTIC_TYPES from './analytic.types';

const initialState = {
  awaitingTotal: 0,
  oldestCaseTime: '',
  privateAwaitingCount: 0,
  oldestPrivate: '',
};

export default function analyticReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_START:
      return initialState;
    case ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_SUCCESS:
      return {
        awaitingTotal: payload.total,
        oldestCaseTime: payload.oldestTime,
        privateAwaitingCount: payload.privateCount,
        oldestPrivate: payload.oldestPrivate,
        otherCount: payload.otherCount,
        oldestOther: payload.oldestOther,
      };
    default:
      return state;
  }
}
