import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({


  menuDivContainer: {
    position: 'relative',
  },
  menuAbsolute: {
    // position: 'absolute',
    // marginTop: '40px',
    // height: 'auto',
    // right: '150px'
    // width: '400px',
  },
  spanLangLabel: {
    margin: '0 18px',
    color: '#0381A0',
    fontSize: '16px',
    lineHeight: '24px',
  },
  menuItemPadding: {
    padding: '0px 25px',
    justifyContent: 'center',
  },
  iconButton: {
    padding: '5px'
  }
}));