import React, { memo } from 'react';
import { forwardRef } from 'react';
import { StyledButton } from './styles';

const IconButton = forwardRef(({ alt, src, children, ...rest }, ref) => {
  return (
    <StyledButton ref={ref} {...rest}>
      {children ? children : <img src={src} alt={alt} />}
    </StyledButton>
  );
});

export default memo(IconButton);
