import { fork, put, takeLatest, select, call } from 'redux-saga/effects';
import delay from '@redux-saga/delay-p';
import * as PATIENT_LIST_TYPES from './patientList.types';

import {
  getPatientsSuccess,
  getPatientsRequest,
  updateGlobalSearchStart,
  updateGlobalSearchFail,
  updatePageLimitStart,
  updatePageLimitFail,
  updatePageOffsetStart,
  updatePageOffsetFail,
  updatePatienstsCaseIdStart,
  updatePatienstsCaseIdFail,
  updatePatienstsIdStart,
  updatePatienstsIdFail,
  updateCreatedAtStart,
  updateCreatedAtFail,
  updateCreatedAtDateRangeStart,
  updateCreatedAtDateRangeFail,
  updateAgeStart,
  updateAgeFail,
  updateDiagnosedByStart,
  updateDiagnosedByFail,
  updateDiagnosedAtStart,
  updateDiagnosedAtFail,
  resetAllFiltersStart,
  initFilterSettingsStart,
  updateCreatedAtOrderStart,
  updatePageNumberRequestStart,
  updateGenderFail,
  updateStatusStart,
  updateEfficiencyReportFail,
} from './patientList.actions';
import { getPatients } from '../../services/main.service';
import {
  getCurrentPhycisianEmail,
  getCurrentPhycisianPermission,
  getLanguage,
} from '../settings/settings.selectors';
import {
  getAllPatientListFilters,
  getAllPatientListInitialFilters,
  getPageOffset,
  getPatientsTotal,
  getGetPatientsSubscription,
} from './patientList.selectors';
import {
  loadingGlobalOff,
  loadingGlobalOn,
  SETTINGS_LOADING_ACTIONS,
} from '../../store/settings/settings.actions';
import {
  updateGenderStart,
  updateStatusFail,
  updateEfficiencyReportStart,
} from './patientList.actions';

const INTERVAL = 60000;
const FILTER_LOCAL_STORAGE_KEY = 'DD_USER_FILTER_SETTINGS';

function* fetchFiltersFromLocalStorage() {
  try {
    const filterSettingsStr = yield localStorage.getItem(
      FILTER_LOCAL_STORAGE_KEY
    );
    return JSON.parse(filterSettingsStr);
  } catch (e) {
    console.log(`cannot fetch filters from local storage.`);
    const filters = yield select(getAllPatientListFilters);
    return filters;
  }
}

function* persistFiltersToLocalStorage(setting) {
  try {
    const filterSettingsStr = yield localStorage.getItem(
      FILTER_LOCAL_STORAGE_KEY
    );
    const currentSettings = JSON.parse(filterSettingsStr);
    const filterSettings = JSON.stringify({ ...currentSettings, ...setting });
    yield localStorage.setItem(FILTER_LOCAL_STORAGE_KEY, filterSettings);
  } catch (e) {
    console.log(`cannot fetch filters from local storage.`);
  }
}

function* getPatientsWORK(payload) {
  const load = payload?.load;
  try {
    if (load) {
      yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.getPatientsWORK));
    }
    const isSubscribed = yield select(getGetPatientsSubscription);
    if (isSubscribed) {
      const physician = yield select(getCurrentPhycisianEmail);
      const permission = yield select(getCurrentPhycisianPermission);
      const filters = yield select(getAllPatientListFilters);
      const lang = yield select(getLanguage);
      const response = yield call(getPatients, {
        physician,
        permission,
        ...filters,
        statuses: [filters.statuses],
        lang, // limit, offset, orderBy
      });
      if (load) {
        yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.getPatientsWORK));
      }
      if (response) {
        yield put(getPatientsSuccess(response));
      } else {
        throw new Error(`getPatientsWORK -> cannot fetch data.`);
      }
    }
  } catch (e) {
    if (load) {
      put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.getPatientsWORK));
    }
    console.log(`getPatientsWORK -> failed to fetch patienst from list.`);
  } finally {
    try {
      const isSubscribed = yield select(getGetPatientsSubscription);
      if (isSubscribed) {
        yield delay(INTERVAL);
        yield put(getPatientsRequest(false));
      }
    } catch (e) {
      if (load) {
        put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.getPatientsWORK));
      }
      console.log(`getPatientsWORK -> failed to fetch patienst from list.`);
    }
  }
}

function* updateGlobalSearchStringWORK(action) {
  try {
    const globalFilterString = action.payload.globalFilterString;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateGlobalSearchStart(globalFilterString));
    yield persistFiltersToLocalStorage({
      globalFilterString,
      page: 0,
      offset: 0,
    });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updateGlobalSearchStringWORK -> global search update failed`);
    yield put(updateGlobalSearchFail(e));
  }
}

function* updatePageLimitSizeWORK(action) {
  yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updatePageLimitSizeWORK));
  try {
    const limit = action.payload.limit;
    yield put(updatePageLimitStart(limit));
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updatePageLimitSizeWORK)
    );

    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updatePageLimitSizeWORK)
    );

    console.log(`updatePageLimitSizeWORK -> limit failed failed`);
    yield put(updatePageLimitFail(e));
  }
}

function* updatePageOffsetWORK(action) {
  try {
    const offset = action.payload.offset;
    yield put(updatePageOffsetStart(offset));
    yield persistFiltersToLocalStorage({ offset });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updatePageOffsetWORK -> offset failed`);
    yield put(updatePageOffsetFail(e));
  }
}

function* updatePatientsCaseIdWORK(action) {
  try {
    const valueCaseId = action.payload.valueCaseId;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updatePatienstsCaseIdStart(valueCaseId));
    yield persistFiltersToLocalStorage({ valueCaseId, page: 0, offset: 0 });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updatePatientsCaseIdWORK -> case id failed.`);
    yield put(updatePatienstsCaseIdFail(e));
  }
}

function* updatePatientsIdWORK(action) {
  try {
    const valuePatientId = action.payload.valuePatientId;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updatePatienstsIdStart(valuePatientId));
    yield persistFiltersToLocalStorage({ valuePatientId, page: 0, offset: 0 });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updatePatientsIdWORK -> search by patient id failed.`);
    yield put(updatePatienstsIdFail(e));
  }
}

function* updateCreatedAtValueWORK(action) {
  try {
    const valueCreatedTime = action.payload.valueCreatedTime;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateCreatedAtStart(valueCreatedTime));
    yield persistFiltersToLocalStorage({
      valueCreatedTime,
      page: 0,
      offset: 0,
    });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updateCreatedAtValueWORK -> filter created at failed`);
    yield put(updateCreatedAtFail(e));
  }
}

function* updateCreatedAtValueInDateRangeWORK({ payload: { dateRange } }) {
  try {
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateCreatedAtDateRangeStart(dateRange));
    yield persistFiltersToLocalStorage({ dateRange, page: 0, offset: 0 });
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    console.log(`updateCreatedAtValueInDateRangeWORK -> date range is failed`);
    yield put(updateCreatedAtDateRangeFail(e));
  }
}

function* updateAgeValueWORK(action) {
  try {
    yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateAgeValueWORK));
    const age = action.payload.age;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateAgeStart(age));
    yield persistFiltersToLocalStorage({ age, page: 0, offset: 0 });
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateAgeValueWORK));
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateAgeValueWORK));
    console.log(`updateAgeValueWORK -> filter age failed`);
    yield put(updateAgeFail(e));
  }
}

function* updateGenderValueWORK(action) {
  try {
    yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateGenderValueWORK));

    const gender = action.payload.gender;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateGenderStart(gender));
    yield persistFiltersToLocalStorage({ gender, page: 0, offset: 0 });
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateGenderValueWORK));
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateGenderValueWORK));
    console.log(`updateGenderValueWORK -> filter gender failed`);
    yield put(updateGenderFail(e));
  }
}

function* updateStatusValueWORK(action) {
  try {
    yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateStatusValueWORK));

    const statuses = action.payload.statuses;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateStatusStart(statuses));
    yield persistFiltersToLocalStorage({ statuses, page: 0, offset: 0 });
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateStatusValueWORK));
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateStatusValueWORK));
    console.log(`updateStatusValueWORK -> filter statuses failed`);
    yield put(updateStatusFail(e));
  }
}

function* updateEfficiencyReportValueWORK(action) {
  try {
    yield put(
      loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateEfficiencyReportValueWORK)
    );

    const efficiencyReport = action.payload.efficiencyReport;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateEfficiencyReportStart(efficiencyReport));
    yield persistFiltersToLocalStorage({
      efficiencyReport,
      page: 0,
      offset: 0,
    });
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateEfficiencyReportValueWORK)
    );
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateEfficiencyReportValueWORK)
    );
    console.log(
      `updateEfficiencyReportValueWORK -> filter efficiencyReport failed`
    );
    yield put(updateEfficiencyReportFail(e));
  }
}

function* updateDiagnosedByValueWORK(action) {
  try {
    yield put(
      loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateDiagnosedByValueWORK)
    );

    const valueDiagnosedBy = action.payload.valueDiagnosedBy;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateDiagnosedByStart(valueDiagnosedBy));
    yield persistFiltersToLocalStorage({
      valueDiagnosedBy,
      page: 0,
      offset: 0,
    });

    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateDiagnosedByValueWORK)
    );
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateDiagnosedByValueWORK)
    );
    console.log(`updateDiagnosedByValueWORK -> diagnosed by failed`);
    yield put(updateDiagnosedByFail(e));
  }
}

function* updateDiagnosedAtValueWORK(action) {
  try {
    yield put(
      loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateDiagnosedAtValueWORK)
    );

    const valueDiagnosedAt = action.payload.valueDiagnosedAt;
    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateDiagnosedAtStart(valueDiagnosedAt));
    yield persistFiltersToLocalStorage({
      valueDiagnosedAt,
      page: 0,
      offset: 0,
    });
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateDiagnosedAtValueWORK)
    );
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateDiagnosedAtValueWORK)
    );
    console.log(`updateDiagnosedAtValueWORK -> diagnosed at failed`);
    yield put(updateDiagnosedAtFail(e));
  }
}

function* resetAllFilterWORK() {
  try {
    yield put(loadingGlobalOn(SETTINGS_LOADING_ACTIONS.resetAllFilterWORK));

    yield put(resetAllFiltersStart());
    const filters = yield select(getAllPatientListInitialFilters);
    console.log(' resetAllFilterWORK filters', filters);
    yield persistFiltersToLocalStorage(filters);

    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.resetAllFilterWORK));
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(loadingGlobalOff(SETTINGS_LOADING_ACTIONS.resetAllFilterWORK));
    console.log(`resetAllFilterWORK -> rest filters failed`);
  }
}

function* updateCreatedAtOrderWORK({ payload: orderCreatedAt }) {
  try {
    yield put(
      loadingGlobalOn(SETTINGS_LOADING_ACTIONS.updateCreatedAtOrderWORK)
    );

    yield put(updatePageOffsetStart(0));
    yield put(updatePageNumberRequestStart(0));
    yield put(updateCreatedAtOrderStart(orderCreatedAt));
    yield persistFiltersToLocalStorage({ orderCreatedAt, page: 0, offset: 0 });
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateCreatedAtOrderWORK)
    );
    yield call(getPatientsWORK, { load: true });
  } catch (e) {
    yield put(
      loadingGlobalOff(SETTINGS_LOADING_ACTIONS.updateCreatedAtOrderWORK)
    );
    console.log(`updateCreatedAtOrderWORK -> asc / desc order failed`);
    yield put(updateDiagnosedAtFail(e));
  }
}

function* updatePageNumberWORK({ payload: { page } }) {
  try {
    const stateOffset = yield select(getPageOffset);
    const stateTotal = yield select(getPatientsTotal);
    if (stateOffset <= stateTotal) {
      yield put(updatePageNumberRequestStart(page));
      yield persistFiltersToLocalStorage({ page });
      yield call(getPatientsWORK, { load: true });
    } else {
      console.log(`page is out of possible set.`);
    }
  } catch (e) {
    console.log(`updatePageNumberWORK -> page number failed`);
    yield put(updateDiagnosedAtFail(e));
  }
}

function* initFilterSettingsWORK() {
  try {
    const filterSettings = yield fetchFiltersFromLocalStorage();
    yield put(initFilterSettingsStart(filterSettings));
  } catch (e) {
    console.log(
      `initFilterSettingsWORK -> cannot fetch filters from local storage`
    );
  }
}

function* initFilterSettings() {
  yield takeLatest(
    PATIENT_LIST_TYPES.initFilterSettings,
    initFilterSettingsWORK
  );
}

function* watchGetPatients() {
  yield takeLatest(PATIENT_LIST_TYPES.getPatients, getPatientsWORK);
}

function* watchUpdateGlobalSearchString() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateGlobalSearch,
    updateGlobalSearchStringWORK
  );
}

function* watchUpdatePageLimit() {
  yield takeLatest(PATIENT_LIST_TYPES.updatePageLimit, updatePageLimitSizeWORK);
}

function* watchUpdatePageOffset() {
  yield takeLatest(PATIENT_LIST_TYPES.updatePageOffset, updatePageOffsetWORK);
}

function* watchUpdatePatientsCaseId() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updatePatientsCaseId,
    updatePatientsCaseIdWORK
  );
}

function* watchUpdatePatientsId() {
  yield takeLatest(PATIENT_LIST_TYPES.updatePatientsId, updatePatientsIdWORK);
}

function* watchUpdateCreatedAtValue() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateCreatedAtValue,
    updateCreatedAtValueWORK
  );
}

function* watchUpdateCreatedAtInDateRangeValue() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateCreatedAtDateRangeValue,
    updateCreatedAtValueInDateRangeWORK
  );
}

function* watchUpdateAgeValue() {
  yield takeLatest(PATIENT_LIST_TYPES.updateAgeValue, updateAgeValueWORK);
}

function* watchUpdateGenderValue() {
  yield takeLatest(PATIENT_LIST_TYPES.updateGenderValue, updateGenderValueWORK);
}

function* watchUpdateStatusValue() {
  yield takeLatest(PATIENT_LIST_TYPES.updateStatusValue, updateStatusValueWORK);
}

function* watchUpdateEfficiencyReportValue() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateEfficiencyReportValue,
    updateEfficiencyReportValueWORK
  );
}

function* watchUpdateDiagnosedByValue() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateDiagnosedByValue,
    updateDiagnosedByValueWORK
  );
}

function* watchUpdateDiagnosedAtValue() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateDiagnosedAtValue,
    updateDiagnosedAtValueWORK
  );
}

function* watchResetAllFilters() {
  yield takeLatest(PATIENT_LIST_TYPES.resetAllFilters, resetAllFilterWORK);
}

function* watchUpdateCreatedAtOrder() {
  yield takeLatest(
    PATIENT_LIST_TYPES.updateCreatedAtOrder,
    updateCreatedAtOrderWORK
  );
}

function* watchUpdatePageNumber() {
  yield takeLatest(PATIENT_LIST_TYPES.updatePageNumber, updatePageNumberWORK);
}

const patientListSagas = [
  fork(watchGetPatients),
  fork(initFilterSettings),
  fork(watchUpdateAgeValue),
  fork(watchUpdateGenderValue),
  fork(watchUpdateStatusValue),
  fork(watchUpdateEfficiencyReportValue),
  fork(watchResetAllFilters),
  fork(watchUpdatePageLimit),
  fork(watchUpdatePageNumber),
  fork(watchUpdatePageOffset),
  fork(watchUpdatePatientsId),
  fork(watchUpdatePatientsCaseId),
  fork(watchUpdateCreatedAtValue),
  fork(watchUpdateCreatedAtOrder),
  fork(watchUpdateDiagnosedByValue),
  fork(watchUpdateDiagnosedAtValue),
  fork(watchUpdateGlobalSearchString),
  fork(watchUpdateCreatedAtInDateRangeValue),
];

export default patientListSagas;
