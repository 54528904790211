import { DEFAUL_LIMIT } from './patientList.reducer';

import * as PATIENT_LIST_TYPES from './patientList.types';

// initial interval process started
export const initFilterSettings = () => ({
  type: PATIENT_LIST_TYPES.initFilterSettings,
});

export const initFilterSettingsStart = (filters) => ({
  type: PATIENT_LIST_TYPES.initFilterSettingsStart,
  payload: { filters },
});

export const getPatientsRequest = (load = false) => ({
  type: PATIENT_LIST_TYPES.getPatients,
  payload: { load },
});

export const unsubscribeGetPatientsRequest = () => ({
  type: PATIENT_LIST_TYPES.unsubscrbeGetPatients,
});
export const subscribeGetPatientsRequest = () => ({
  type: PATIENT_LIST_TYPES.subscrbeGetPatients,
});

export const getPatients = () => ({
  type: PATIENT_LIST_TYPES.getPatientsStart,
});

export const getPatientsSuccess = ({ patients, total }) => ({
  type: PATIENT_LIST_TYPES.getPatientsSuccess,
  payload: { patients, total },
});

export const getPatientsFail = (error) => ({
  type: PATIENT_LIST_TYPES.getPatientsFail,
  payload: { error },
});

// update global search started
export const updateGlobalSearchRequest = (globalFilterString) => ({
  type: PATIENT_LIST_TYPES.updateGlobalSearch,
  payload: { globalFilterString },
});

export const updateGlobalSearchStart = (globalFilterString) => ({
  type: PATIENT_LIST_TYPES.updateGlobalSearchStart,
  payload: { globalFilterString },
});

export const updateGlobalSearchSuccess = ({ patients, total }) => ({
  type: PATIENT_LIST_TYPES.updateGlobalSearchSuccess,
  payload: { patients, total },
});

export const updateGlobalSearchFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateGlobalSearchFail,
  payload: { error },
});

// update page limit started
export const updatePageLimitRequest = (limitStr) => {
  try {
    const limit = parseInt(limitStr);
    return {
      type: PATIENT_LIST_TYPES.updatePageLimit,
      payload: { limit },
    };
  } catch (e) {
    return {
      type: PATIENT_LIST_TYPES.updatePageLimit,
      payload: { limit: DEFAUL_LIMIT },
    };
  }
};

export const updatePageLimitStart = (limit) => ({
  type: PATIENT_LIST_TYPES.updatePageLimitStart,
  payload: { limit },
});

export const updatePageLimitFail = (error) => ({
  type: PATIENT_LIST_TYPES.updatePageLimitFail,
  payload: { error },
});

// update page number started
export const updatePageOffsetRequest = (offset) => ({
  type: PATIENT_LIST_TYPES.updatePageOffset,
  payload: { offset },
});

export const updatePageOffsetStart = (offset) => ({
  type: PATIENT_LIST_TYPES.updatePageOffsetStart,
  payload: { offset },
});

export const updatePageOffsetFail = (error) => ({
  type: PATIENT_LIST_TYPES.updatePageOffsetFail,
  payload: { error },
});

// update patients case id started
export const updatePatienstsCaseIdRequest = (valueCaseId) => ({
  type: PATIENT_LIST_TYPES.updatePatientsCaseId,
  payload: { valueCaseId },
});

export const updatePatienstsCaseIdStart = (valueCaseId) => ({
  type: PATIENT_LIST_TYPES.updatePatientsCaseIdStart,
  payload: { valueCaseId },
});

export const updatePatienstsCaseIdFail = (error) => ({
  type: PATIENT_LIST_TYPES.updatePatientsCaseIdFail,
  payload: { error },
});

// update patients id started
export const updatePatienstsIdRequest = (valuePatientId) => ({
  type: PATIENT_LIST_TYPES.updatePatientsId,
  payload: { valuePatientId },
});

export const updatePatienstsIdStart = (valuePatientId) => ({
  type: PATIENT_LIST_TYPES.updatePatientsIdStart,
  payload: { valuePatientId },
});

export const updatePatienstsIdFail = (error) => ({
  type: PATIENT_LIST_TYPES.updatePatientsIdFail,
  payload: { error },
});

// update created_at value started
export const updateCreatedAtRequest = (valueCreatedTime) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtValue,
  payload: { valueCreatedTime },
});

export const updateCreatedAtStart = (valueCreatedTime) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtValueStart,
  payload: { valueCreatedTime },
});

export const updateCreatedAtFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtValueFail,
  payload: { error },
});

// update created_at in date range started
export const updateCreatedAtDateRangeRequest = (dateRange) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtDateRangeValue,
  payload: { dateRange },
});

export const updateCreatedAtDateRangeStart = (dateRange) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtValueDateRangeStart,
  payload: { dateRange },
});

export const updateCreatedAtDateRangeFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtValueDateRangeFail,
  payload: { error },
});

// update age  started
export const updateAgeRequest = (age) => ({
  type: PATIENT_LIST_TYPES.updateAgeValue,
  payload: { age },
});

export const updateAgeStart = (age) => ({
  type: PATIENT_LIST_TYPES.updateAgeValueStart,
  payload: { age },
});

export const updateAgeFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateAgeValueFail,
  payload: { error },
});

// update gender started
export const updateGenderRequest = (gender) => ({
  type: PATIENT_LIST_TYPES.updateGenderValue,
  payload: { gender },
});

export const updateGenderStart = (gender) => ({
  type: PATIENT_LIST_TYPES.updateGenderValueStart,
  payload: { gender },
});

export const updateGenderFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateGenderValueFail,
  payload: { error },
});

// update status started
export const updateStatusRequest = (statuses) => ({
  type: PATIENT_LIST_TYPES.updateStatusValue,
  payload: { statuses },
});

export const updateStatusStart = (statuses) => ({
  type: PATIENT_LIST_TYPES.updateStatusValueStart,
  payload: { statuses },
});

export const updateStatusFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateStatusValueFail,
  payload: { error },
});

// update efficiencyReport started
export const updateEfficiencyReportRequest = (efficiencyReport) => ({
  type: PATIENT_LIST_TYPES.updateEfficiencyReportValue,
  payload: { efficiencyReport },
});

export const updateEfficiencyReportStart = (efficiencyReport) => ({
  type: PATIENT_LIST_TYPES.updateEfficiencyReportValueStart,
  payload: { efficiencyReport },
});

export const updateEfficiencyReportFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateEfficiencyReportValueFail,
  payload: { error },
});

// update diagnosed by  started
export const updateDiagnosedByRequest = (valueDiagnosedBy) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedByValue,
  payload: { valueDiagnosedBy },
});

export const updateDiagnosedByStart = (valueDiagnosedBy) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedByValueStart,
  payload: { valueDiagnosedBy },
});

export const updateDiagnosedByFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedByValueFail,
  payload: { error },
});

// update diagnosed at  started
export const updateDiagnosedAtRequest = (valueDiagnosedAt) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedAtValue,
  payload: { valueDiagnosedAt },
});

export const updateDiagnosedAtStart = (valueDiagnosedAt) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedAtValueStart,
  payload: { valueDiagnosedAt },
});

export const updateDiagnosedAtFail = (error) => ({
  type: PATIENT_LIST_TYPES.updateDiagnosedAtValueFail,
  payload: { error },
});

// reset all filters started
export const resetAllFilters = () => ({
  type: PATIENT_LIST_TYPES.resetAllFilters,
});

export const resetAllFiltersStart = () => ({
  type: PATIENT_LIST_TYPES.resetAllFiltersStart,
});

// reset created at order started
export const updateCreatedAtOrder = (orderCreatedAt) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtOrder,
  payload: orderCreatedAt,
});

export const updateCreatedAtOrderStart = (orderCreatedAt) => ({
  type: PATIENT_LIST_TYPES.updateCreatedAtOrderStart,
  payload: orderCreatedAt,
});

// update page number started
export const updatePageNumberRequest = (page) => ({
  type: PATIENT_LIST_TYPES.updatePageNumber,
  payload: { page },
});

export const updatePageNumberRequestStart = (page) => ({
  type: PATIENT_LIST_TYPES.updatePageNumberStart,
  payload: page,
});
