export const loadingGlobalOn = 'LOADING_GLOBAL_ON';
export const loadingGlobalOff = 'LOADING_GLOBAL_OFF';
export const setAiModel = 'setAiModel';
export const UPDATE_IS_ON_VACATION = 'UPDATE_IS_ON_VACATION';
export const SET_USER = 'SET_USER';
export const SET_ISVALIDATIONCODE = 'SET_ISVALIDATIONCODE';
export const SET_LOGIN_PROCESS = 'SET_LOGIN_PROCESS';
export const SET_FAILED_AVAILABLE_CASES = 'SET_FAILED_AVAILABLE_CASES';

export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX';
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';
export const CHANGE_USER_LANGUAGE = 'CHANGE_USER_LANGUAGE';
