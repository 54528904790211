export const COPY_PATIENT_ID = 'COPY_PATIENT_ID';
export const CREATE_TREATMENT_PLAN_START = 'CREATE_TREATMENT_PLAN_START';
export const CREATE_TREATMENT_PLAN_SUCCESS = 'CREATE_TREATMENT_PLAN_SUCCESS';
export const CREATE_TREATMENT_PLAN_FAIL = 'CREATE_TREATMENT_PLAN_FAIL';
export const CREATE_TREATMENT_PLAN_RESET = 'CREATE_TREATMENT_PLAN_RESET';
export const GET_CASES_BATCH_INFO = 'GET_CASES_BATCH_INFO';
export const CONFIRM_TER = 'CONFIRM_TER';
export const INIT_PATIENT_DETAILS = 'INIT_PATIENT_DETAILS';
export const UPDATE_CURRENT_CASE_INDEX = 'UPDATE_CURRENT_CASE_INDEX';
export const UPDATE_AWAITING_CASES_ARRAY = 'UPDATE_AWAITING_CASES_ARRAY';
export const FILL_AWAITING_CASES_ARRAY = 'FILL_AWAITING_CASES_ARRAY';
export const CHANGE_STATUS_STATE = 'CHANGE_STATUS_STATE';
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const PATIENT_DETAILS_LOAD_START = 'PATIENT_DETAILS_LOAD_START';
export const PATIENT_DETAILS_LOAD_END_ERROR = 'PATIENT_DETAILS_LOAD_END_ERROR';
export const PATIENT_DETAILS_LOAD_END = 'PATIENT_DETAILS_LOAD_END';
export const FETCH_ANNOTATIONS_START = 'FETCH_ANNOTATIONS_START';
export const GET_ANNOTATIONS = 'GET_ANNOTATIONS';
export const SET_ANNOTATIONS = 'SET_ANNOTATIONS';
export const SET_MESSAGES_TO_PATIENT_DIALOG = 'SET_MESSAGES_TO_PATIENT_DIALOG';
export const GET_PATIENT_DETAILS = 'GET_PATIENT_DETAILS';
export const SET_IS_IMAGE_VIEWED = 'SET_IS_IMAGE_VIEWED';
export const UPDATE_STATUS = 'UPDATE_STATUS';

export const UPDATE_BLOCK_CASES_BATCH_REQUEST =
  'UPDATE_BLOCK_CASES_BATCH_REQUEST';
export const UPDATE_UNBLOCK_CASES_BATCH_START =
  'UPDATE_UNBLOCK_CASES_BATCH_START';
export const UPDATE_BLOCK_CASES_BATCH_START = 'UPDATE_BLOCK_CASES_BATCH_START';

export const UPDATE_UNBLOCK_CASES_BATCH_SUCCESS =
  'UPDATE_UNBLOCK_CASES_BATCH_SUCCESS';
export const UPDATE_BLOCK_CASES_BATCH_SUCCESS =
  'UPDATE_BLOCK_CASES_BATCH_SUCCESS';

export const UPDATE_UNBLOCK_CASES_BATCH_FAIL =
  'UPDATE_UNBLOCK_CASES_BATCH_FAIL';
export const UPDATE_BLOCK_CASES_BATCH_FAIL = 'UPDATE_BLOCK_CASES_BATCH_FAIL';

export const SET_IMAGES_LOADING = 'SET_IMAGES_LOADING';
export const SET_ML_DIAGNOSIS_PROBABILITY = 'SET_ML_DIAGNOSIS_PROBABILITY';
export const VIEW_NEXT_AVAILABLE_CASE = 'VIEW_NEXT_AVAILABLE_CASE';
