import * as PATIENT_HISTORY_TYPES from './patientHistory.types';
const initialState = {
  diseasesLoading: false,
  diseases: [],
  diseasesError: null,
};

const patientHistoryReducer = (
  state = initialState,
  { type, payload, error }
) => {
  switch (type) {
    case PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_START: {
      return {
        ...state,
        diseasesLoading: true,
        diseases: initialState.diseases,
        diseasesError: initialState.error,
      };
    }
    case PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_SUCCESS: {
      return {
        ...state,
        diseasesLoading: false,
        diseases: payload,
      };
    }
    case PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_FAIL: {
      return {
        ...state,
        diseasesLoading: false,
        diseasesError: error.message,
      };
    }
    default: {
      return state;
    }
  }
};
export default patientHistoryReducer;
