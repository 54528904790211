export const copyTextToClipboard = (text) => {
  if (!text) return;
  let textField = document.createElement('textarea');
  textField.innerHTML = text;
  document.body.appendChild(textField);
  textField.select();
  let result = document.execCommand('copy');
  if (!result) {
    try {
      window.navigator.clipboard.writeText(text).then(
        function () {
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    } catch (e) {}
  }
  textField.remove();
};

export const copyPhoneToClipboard = (phone) => {
  if (!phone) return;
  phone = phone.replace(/-/g, '');
  copyTextToClipboard(phone);
};
