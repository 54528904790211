import React, { useState } from 'react';
import { Dialog, Typography } from '@material-ui/core';
import CustomInput from '../../shared/CustomInput';
import { useStyles } from './styles';
import CustomButton from '../../shared/CustomButton';
import SendIcon from '@material-ui/icons/SendOutlined';
import { resetPhysicianPassword } from '../../../services/auth.service';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from '../../../helpers/constants';

const ResetPasswordDialog = ({ open, onClose }) => {
  const s = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const [input, setInput] = useState({ value: '', error: '' });

  const handleChange = ({ target: { value } }) => {
    const error = EMAIL_REGEX.test(value) ? '' : 'IncorrectEmail';
    setInput({ value, error });
  };

  const handleClose = () => {
    setInput({ value: '', error: '' });
    onClose && onClose();
  };

  const handleSubmit = async () => {
    try {
      const email = input.value;
      await resetPhysicianPassword(email);
      history.push(`/create-password?email=${email}`);
    } catch (e) {
      const message = e?.response?.data?.message || 'DEFAULT';
      setInput((prev) => ({
        ...prev,
        error: t(`forgotPassword.errors.${message}`),
      }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      classes={{ paper: s.paper }}
    >
      <Typography variant="h4" className={s.title}>
        {t('forgotPassword.title')}
      </Typography>
      <CustomInput
        className={s.input}
        error={!!input.error}
        helperText={input.error}
        placeholder="email"
        variant="standard"
        type="email"
        isNoLabel
        value={input.value}
        onChangeHandler={handleChange}
        onKeyPressHandler={handleSubmit}
      />
      <CustomButton
        type="submit"
        variant="contained"
        disabled={!input.value || !!input.error}
        className={s.button}
        startIcon={<SendIcon style={{ marginRight: '10px' }} />}
        onClick={handleSubmit}
      >
        Send
      </CustomButton>
    </Dialog>
  );
};
export default ResetPasswordDialog;
