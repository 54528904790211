import styled from 'styled-components';
export const StyledButton = styled.button`
  width: 26px;
  height: 26px;
  border: none;
  padding: 0;
  background: none;
  :active,
  :focus {
    outline: none;
  }
  > * {
    max-width: 26px;
    max-height: 26px;
  }
`;
