import * as PATIENT_LIST_TYPES from './patientList.types';
import {
  BABY_AGE_OPTIONS,
  TIME_OPTIONS,
  LIST_ORDER,
  GENDER_OPTIONS,
  REPORT_OPTIONS,
  STATUS_FILTER_OPTIONS2,
} from '../../components/PatientList/helper/filters';

export const DEFAUL_LIMIT = 100;
export const DEFAUL_OFFSET = 0;
export const DEFAULT_STATUSES = [
  'filterForAll',
  'status1',
  'status2',
  'status3',
  'status33',
  'status4',
  'status9',
];

export const initPatientListState = {
  patients: [],
  subscribePatients: false,
  totalCount: 0,
  patientsLoading: false,
  filterPending: false,
  order: LIST_ORDER.desc,
  orderCreatedAt: '',
  limit: DEFAUL_LIMIT,
  offset: DEFAUL_OFFSET,
  page: 0,
  statuses: STATUS_FILTER_OPTIONS2.filterForAll,
  error: null,
  modifiedAt: new Date(),
  globalFilterString: '',
  valueCaseId: '',
  valueCreatedTime: TIME_OPTIONS.all,
  valueDiagnosedAt: TIME_OPTIONS.all,
  valueDiagnosedBy: TIME_OPTIONS.all,
  valuePatientId: '',
  age: BABY_AGE_OPTIONS.ageAll,
  gender: GENDER_OPTIONS.genderAll,
  efficiencyReport: REPORT_OPTIONS.filterForAll,
  dateRange: null,
  orderBy: 'asc',
};

const patientListReducer = (state = initPatientListState, action) => {
  switch (action.type) {
    case PATIENT_LIST_TYPES.subscrbeGetPatients:
      return {
        ...state,
        subscribePatients: true,
      };

    case PATIENT_LIST_TYPES.unsubscrbeGetPatients:
      return {
        ...state,
        subscribePatients: false,
      };
    case PATIENT_LIST_TYPES.initFilterSettingsStart:
      return {
        ...state,
        filterPending: false,
        limit: action.payload.filters.limit,
        offset: action.payload.filters.offset,
        globalFilterString: action.payload.filters.globalFilterString,
        valueCaseId: action.payload.filters.valueCaseId,
        valueCreatedTime: action.payload.filters.valueCreatedTime,
        valueDiagnosedAt: action.payload.filters.valueDiagnosedAt,
        valueDiagnosedBy: action.payload.filters.valueDiagnosedBy,
        valuePatientId: action.payload.filters.valuePatientId,
        age: action.payload.filters.age,
        gender: action.payload.filters.gender,
        efficiencyReport: action.payload.filters.efficiencyReport,
        dateRange: action.payload.filters.dateRange,
        orderCreatedAt: action.payload.filters.orderCreatedAt,
        page: action.payload.filters.page,
      };
    case PATIENT_LIST_TYPES.resetAllFiltersStart:
      return {
        ...state,
        filterPending: true,
        limit: initPatientListState.limit,
        offset: initPatientListState.offset,
        globalFilterString: initPatientListState.globalFilterString,
        valueCaseId: initPatientListState.valueCaseId,
        valueCreatedTime: initPatientListState.valueCreatedTime,
        valueDiagnosedAt: initPatientListState.valueDiagnosedAt,
        valueDiagnosedBy: initPatientListState.valueDiagnosedBy,
        valuePatientId: initPatientListState.valuePatientId,
        age: initPatientListState.age,
        gender: initPatientListState.gender,
        efficiencyReport: initPatientListState.efficiencyReport,
        dateRange: initPatientListState.dateRange,
        orderCreatedAt: initPatientListState.orderCreatedAt,
        page: initPatientListState.page,
        statuses: initPatientListState.statuses,
      };
    case PATIENT_LIST_TYPES.updatePageNumberStart:
      return {
        ...state,
        filterPending: true,
        page: action.payload,
      };
    case PATIENT_LIST_TYPES.updateCreatedAtOrderStart:
      return {
        ...state,
        filterPending: true,
        orderCreatedAt: action.payload,
      };
    case PATIENT_LIST_TYPES.updateDiagnosedAtValueStart:
      return {
        ...state,
        filterPending: true,
        valueDiagnosedAt: action.payload.valueDiagnosedAt,
      };
    case PATIENT_LIST_TYPES.updateDiagnosedByValueStart:
      return {
        ...state,
        filterPending: true,
        valueDiagnosedBy: action.payload.valueDiagnosedBy,
      };
    case PATIENT_LIST_TYPES.updateAgeValueStart:
      return {
        ...state,
        filterPending: true,
        age: action.payload.age,
      };
    case PATIENT_LIST_TYPES.updateGenderValueStart:
      return {
        ...state,
        filterPending: true,
        gender: action.payload.gender,
      };
    case PATIENT_LIST_TYPES.updateStatusValueStart:
      return {
        ...state,
        filterPending: true,
        statuses: action.payload.statuses,
      };
    case PATIENT_LIST_TYPES.updateEfficiencyReportValueStart:
      return {
        ...state,
        filterPending: true,
        efficiencyReport: action.payload.efficiencyReport,
      };
    case PATIENT_LIST_TYPES.updateCreatedAtValueDateRangeStart:
      return {
        ...state,
        filterPending: true,
        dateRange: action.payload.dateRange,
      };
    case PATIENT_LIST_TYPES.updateCreatedAtValueStart:
      return {
        ...state,
        filterPending: true,
        valueCreatedTime: action.payload.valueCreatedTime,
      };
    case PATIENT_LIST_TYPES.updatePatientsIdStart:
      return {
        ...state,
        filterPending: true,
        valuePatientId: action.payload.valuePatientId,
      };
    case PATIENT_LIST_TYPES.updatePatientsCaseIdStart:
      return {
        ...state,
        filterPending: true,
        valueCaseId: action.payload.valueCaseId,
      };
    case PATIENT_LIST_TYPES.updateGlobalSearchStart:
      return {
        ...state,
        filterPending: true,
        globalFilterString: action.payload.globalFilterString,
      };
    case PATIENT_LIST_TYPES.updatePageLimitStart:
      return {
        ...state,
        filterPending: true,
        limit: action.payload.limit,
      };
    case PATIENT_LIST_TYPES.updatePageOffsetStart:
      return {
        ...state,
        filterPending: true,
        offset: action.payload.offset,
      };
    case PATIENT_LIST_TYPES.getPatientsStart:
      return {
        ...state,
        patientsLoading: true,
      };
    case PATIENT_LIST_TYPES.getPatientsSuccess:
      return {
        ...state,
        patientsLoading: false,
        patients: action.payload.patients,
        totalCount: action.payload.total,
        subscribePatients: true,
      };
    case PATIENT_LIST_TYPES.updatePageLimitFail:
    case PATIENT_LIST_TYPES.updateGlobalSearchFail:
    case PATIENT_LIST_TYPES.getPatientsFail:
      return {
        ...state,
        patientsLoading: false,
        error: action.payload.error,
        subscribePatients: false,
      };
    default:
      return state;
  }
};

export default patientListReducer;
