import { axiosInstance } from '../core/axios';

const DefaultEnvSettings = {};
let envSettings = null;

export async function session() {
  try {
    const res = await axiosInstance.get(`/api/auth/session`, {}, {});
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function getEnvSettings() {
  if (envSettings) return envSettings;

  try {
    const res = await axiosInstance.get(`/api/auth/env-settings`);
    if (!res.data) {
      return (envSettings = DefaultEnvSettings);
    }

    envSettings = res.data;
    return res.data;
  } catch (err) {
    console.error('ERROR: Get env settings failed => ', err);
    envSettings = DefaultEnvSettings;
    return envSettings;
  }
}

export async function login(data) {
  const responseBasic = await axiosInstance.post(`/api/auth/login`, data);
  return responseBasic.data;
}

export async function logout() {
  try {
    const responseBasic = await axiosInstance.post(`/api/auth/logout`);
    return responseBasic.data;
  } catch (err) {
    return null;
  }
}

export async function isAuth() {
  try {
    const responseBasic = await axiosInstance.get(`/api/auth/is-auth`);
    return responseBasic.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const invitePhysician = async (id) => {
  const { data } = await axiosInstance.post(`/api/auth/invite/${id}`);
  return data;
};

export const createNewPhysicianPassword = async (body) => {
  const { data } = await axiosInstance.post(`/api/auth/create-password`, body);
  return data;
};

export async function resetPhysicianPassword(email) {
  const res = await axiosInstance.post(`/api/auth/forgot-password`, { email });
  return res.data;
}

export async function validateCode(email, code) {
  try {
    const res = await axiosInstance.post(`/api/auth/registration-validation`, {
      email,
      code,
    });
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function savePhysicianPassword(email, password) {
  try {
    const res = await axiosInstance.post(`/api/auth/registration-password`, {
      email,
      password,
    });
    return res.data;
  } catch (err) {
    return null;
  }
}
