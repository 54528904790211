import * as DISEASES_TYPES from './diseases.types';
export const getLoadDiseasesRequest = (payload) => ({
  type: DISEASES_TYPES.LOAD_DISEASES_REQUEST,
  payload,
});

export const getLoadDiseasesSuccess = (diseases) => ({
  type: DISEASES_TYPES.LOAD_DISEASES_REQUEST_SUCESS,
  payload: diseases,
});
