import { SYSTEM_STATUSES } from '../../components/helpers/groups';
import { AI_MODELS } from '../../helpers/ai-models';
import {
  PRIMARY_PROPERTY_NAME,
  EXCLUDED_PROPERTY_NAME,
} from '../../helpers/sagas.helper';

export const getCreateTreatmentPlanLoading = (state) =>
  state.patientDetails.createTreatmentPlanLoading;
export const getCreateTreatmentPlanSuccess = (state) =>
  state.patientDetails.createTreatmentPlanSuccess;
export const getCreateTreatmentPlanError = (state) =>
  state.patientDetails.createTreatmentPlanError;
export const getUserIdentifier = (state) =>
  state.patientDetails ? state.patientDetails.user_identifier : '';
export const getIsAnnotationsFetchLoading = (state) =>
  state.patientDetails ? state.patientDetails.isAnnotationsFetchLoading : false;
export const getAwaitingCasesList = ({ patientDetails }) =>
  patientDetails.currentCasesArray;
export const getCurrentIndex = (state) => state.patientDetails.currentCaseIndex;
export const getCurrentStatus = (state) => state.patientDetails.status;
export const getCurrentVendorId = (state) => state.patientDetails.vendor_id;
export const getCurrentPrivatePool = (state) =>
  state.patientDetails.private_pool;
export const getPatientAnnotations = (state) =>
  state.patientDetails.annotations;
export const getPatientPhysicianCode = (state) =>
  state.patientDetails.physician_code;
export const getPatientUnresolvedCaseTreatmentEffect = ({ patientDetails }) =>
  patientDetails.unresolvedTreatment;
export const getPatientResolvedCaseTreatmentEffect = ({ patientDetails }) =>
  patientDetails.resolvedTreatment;
export const getCaseUUIDSelector = (state) => state.patientDetails.case_uuid;
export const getPatientTranslated = (state) =>
  state.patientDetails.patientDetailsTranslated;
export const getAllPatientDetails = (state) => state.patientDetails;
export const getPatientCreationDate = (state) =>
  state.patientDetails.created_at;
export const getPatientLoadData = ({ patientDetails }) =>
  patientDetails.load_data;
export const getPendingDescription = (state) =>
  state.patientDetails
    ? state.patientDetails.status === SYSTEM_STATUSES.PENDING &&
      state.patientDetails.descriptions
    : null;
export const getPendingLoading = (state) =>
  state.patientDetails ? state.patientDetails.isPendingLoading : false;
export const getPendingUnblockLoading = (state) =>
  state.patientDetails ? state.patientDetails.isUnblockPendingLoading : false;
export const isImageViewedSelector = ({ patientDetails }) =>
  patientDetails.isImageViewed;
export const getIsImagesLoading = (state) =>
  state.patientDetails ? state.patientDetails.isImagesLoading : true;
export const getUserHash = (state) =>
  state.patientDetails ? state.patientDetails.userHash : '';
export const getIsLoadingSaveCase = (state) =>
  state.patientDetails ? state.patientDetails.isLoadingSaveCase : false;
export const getPatientInfoForTreatmentPlan = (state) => ({
  id: state.patientDetails?.user_id || state.patientDetails?.user_identifier,
  name: state.patientDetails?.patient_name,
  age: state.patientDetails?.patientDetails?.age,
});
export const getPatientRequest = (state) => ({
  durationFromBirth:
    state.patientDetails?.patientDetails['duration.from_birth'],
  itch: state.patientDetails?.patientDetails?.itch?.itch_assessment,
  pain: state.patientDetails?.patientDetails?.pain?.pain_assessment,
  QAIsChangeDensityHairs:
    state.patientDetails?.patientDetails?.QA_Is_Change_Density_Hairs,
  QAIsChangeThicknessHairs:
    state.patientDetails?.patientDetails?.QA_Is_Change_Thickness_Hairs,
  QAIsDensity: state.patientDetails?.patientDetails?.QA_Is_Density || null,
  QAIsThickness: state.patientDetails?.patientDetails?.QA_Is_Thickness || null,
});

export const getConfidence = (state) =>
  state.patientDetails?.mlDiagnosisProbabilities?.confidence || 0;

export const getPrimaryProbabilities = (state) => {
  if (state.patientDetails && state.patientDetails.mlDiagnosisProbabilities) {
    return (
      state.patientDetails.mlDiagnosisProbabilities[PRIMARY_PROPERTY_NAME][
        state?.settings?.aiModel || AI_MODELS.DEFAULT
      ] || []
    );
  } else {
    return [];
  }
};
export const getExcludedProbabilities = (state) => {
  if (state.patientDetails && state.patientDetails.mlDiagnosisProbabilities) {
    return state.patientDetails.mlDiagnosisProbabilities[
      EXCLUDED_PROPERTY_NAME
    ];
  } else {
    return [];
  }
};

export const getDoctorDataDiagnosedBy = (state) => {
  const lang = state.settings.userSettings.language;
  if (state.patientDetails?.doctorDataDiagnosedBy) {
    return {
      prefix: state.patientDetails?.doctorDataDiagnosedBy?.physicianPrefix,
      name: state.patientDetails?.doctorDataDiagnosedBy?.name[lang]?.name || '',
    };
  } else {
    return state.admin && state.admin[lang]
      ? {
          prefix: state.admin.physicianPrefix || '',
          name: state.admin[lang].name || '',
        }
      : { prefix: '', name: '' };
  }
};

export const getMessagesToPatientModal = ({ patientDetails }) =>
  patientDetails.messagesToPatientModal;

export const getRedFlags = ({ patientDetails }) => patientDetails.redFlags;
export const getTriage = ({ patientDetails }) => patientDetails.triage;
