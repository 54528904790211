import i18n from 'i18next';
import { mergeWith } from 'lodash';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import en from './assets/locales/en.json';
import he from './assets/locales/he.json';
import fr from './assets/locales/fr.json';
import ru from './assets/locales/ru.json';
import cn from './assets/locales/cn.json';
import { axiosInstance } from './core/axios';

const resources = { en, he, fr, ru, cn };
let startLanguage;

if (!localStorage.getItem('lang')) {
  startLanguage = 'he';
  localStorage.setItem('lang', startLanguage);
} else {
  startLanguage = localStorage.getItem('lang');
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: startLanguage,
    fallbackLng: startLanguage,
    // keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: '{{lng}}',
      request: (opt, lang, payload, cb) => {
        const translation = resources[lang];
        axiosInstance
          .get('/api/translations', {
            params: {
              lang,
            },
          })
          .then((res) => {
            res.data = mergeWith(translation, res.data, (a, b) =>
              !b ? a : undefined
            );
            cb(null, res);
          })
          .catch(() => cb(null, { data: translation, status: 200 }));
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

export const l = (key, params) => i18n.t(key, params);
