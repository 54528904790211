export const SET_ADMIN_SETTINGS = 'SET_ADMIN_SETTINGS';
export const RESET_ADMIN_SETTINGS = 'RESET_ADMIN_SETTINGS';
export const UPDATE_USER_ACTIVITY = 'UPDATE_USER_ACTIVITY';
export const UPDATE_CURRENT_PHYSICIAN_USER = 'UPDATE_CURRENT_PHYSICIAN_USER';
export const UPDATE_CURRENT_PHYSICIAN_NAME = 'UPDATE_CURRENT_PHYSICIAN_NAME';
export const UPDATE_CURRENT_PHYSICIAN_PHONE =
  'UPDATE_CURRENT_PHYSICIAN_PHONE_ADMIN_PAGE';
export const UPDATE_PHYSICIAN_NAME_PREFIX = 'UPDATE_PHYSICIAN_NAME_PREFIX';
export const UPDATE_CURRENT_PHYSICIAN_SIGNATURE =
  'UPDATE_CURRENT_PHYSICIAN_SIGNATURE';
export const UPDATE_CURRENT_PHYSICIAN_VACATION_PERIOD =
  'UPDATE_CURRENT_PHYSICIAN_VACATION_PERIOD';
export const UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN =
  'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN';
export const UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_SUCCESS =
  'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_SUCCESS';
export const UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_FAIL =
  'UPDATE_ADMIN_SETTINGS_BY_PHYSICIAN_FAIL';
export const UPDATE_DOCTOR_MANAGEMENT_BY_PHYSICIAN =
  'UPDATE_DOCTOR_MANAGEMENT_BY_PHYSICIAN';
export const SAVE_PHYSICIAN_INFORMATION = 'SAVE_PHYSICIAN_INFORMATION';
export const SAVE_PHYSICIAN_INFORMATION_START =
  'SAVE_PHYSICIAN_INFORMATION_START';
export const SAVE_PHYSICIAN_INFORMATION_SUCCESS =
  'SAVE_PHYSICIAN_INFORMATION_SUCCESS';
export const SAVE_PHYSICIAN_INFORMATION_FAIL =
  'SAVE_PHYSICIAN_INFORMATION_FAIL';
// Save physician detail;
export const UPDATE_DOCTOR_MANAGMENT_BY_PHYSICIAN =
  'UPDATE_DOCTOR_MANAGMENT_BY_PHYSICIAN';
export const SAVE_PHYSICIAN_DETAILS = 'SAVE_PHYSICIAN_DETAILS';
export const SAVE_PHYSICIAN_DETAILS_SUCCESS = 'SAVE_PHYSICIAN_DETAILS_SUCCESS';
export const SAVE_PHYSICIAN_DETAILS_FAIL = 'SAVE_PHYSICIAN_DETAILS_FAIL';
// Save physician stam;
export const SAVE_PHYSICIAN_STAMP = 'SAVE_PHYSICIAN_STAMP';
export const SAVE_PHYSICIAN_STAMP_SUCCESS = 'SAVE_PHYSICIAN_STAMP_SUCCESS';
export const SAVE_PHYSICIAN_STAMP_FAIL = 'SAVE_PHYSICIAN_STAMP_FAIL';
// Get physician stam;
export const GET_PHYSICIAN_STAMP_REQUEST = 'GET_PHYSICIAN_STAMP_REQUEST';
export const GET_PHYSICIAN_STAMP_SUCCESS = 'GET_PHYSICIAN_STAMP_SUCCESS';
export const getPhysicianStampFail = 'GET_PHYSICIAN_STAMP_FAIL';
// Save physician profileImag;
export const SAVE_PHYSICIAN_PROFILE_IMAGE_REQUEST =
  'SAVE_PHYSICIAN_PROFILE_IMAGE_REQUEST';
export const savePhysicianProfileImageSuccess =
  'SAVE_PHYSICIAN_PROFILE_IMAGE_SUCCESS';
export const SAVE_PHYSICIAN_PROFILE_IMAGE_FAIL =
  'SAVE_PHYSICIAN_PROFILE_IMAGE_FAIL';
// Get physician profileImag;
export const GET_PHYSICIAN_PROFILE_IMAGE_REQUEST =
  'GET_PHYSICIAN_PROFILE_IMAGE_REQUEST';
export const GET_PHYSICIAN_PROFILE_IMAGE_SUCCESS =
  'GET_PHYSICIAN_PROFILE_IMAGE_SUCCESS';
export const GET_PHYSICIAN_PROFILE_IMAGE_FAIL =
  'GET_PHYSICIAN_PROFILE_IMAGE_FAIL';
export const SET_IS_VACATION_ACTIVATED = 'SET_IS_VACATION_ACTIVATED';
export const UPDATE_PHYSICIAN_PREFIX = 'UPDATE_PHYSICIAN_PREFIX';
export const SET_LOADING_ACT_DEACT = 'SET_LOADING_ACT_DEACT';
export const UPDATE_CURRENT_PHYSICIAN_ALL_NAMES =
  'UPDATE_CURRENT_PHYSICIAN_ALL_NAMES';
