import axios from 'axios';
import { axiosInstance } from '../core/axios';

const CancelToken = axios.CancelToken;

export const getPhysicians = async () => {
  const { data } = await axiosInstance.get('api/doctor');
  return data;
};
export const getPhysician = async (id) => {
  const { data } = await axiosInstance.get(`api/doctor/${id}`);
  return data;
};

export const updatePhysicianById = async (id, body) => {
  const { data } = await axiosInstance.patch(`api/doctor/${id}`, body);
  return data;
};
export const createPhysician = async (body) => {
  const { data } = await axiosInstance.post(`api/doctor`, body);
  return data;
};
export async function switchSMSNotification(data) {
  if (!data || !data.physician || !data.adminSettings) {
    return false;
  }
  const res = await axiosInstance.patch(
    `/api/doctor/update/admin-settings`,
    data
  );
  const status = res.data;
  return status;
}

export const getDoctorDetails = async (email) => {
  try {
    const doctorDetails = await axiosInstance.post(`api/doctor/details`, {
      email,
    });
    return doctorDetails.data;
  } catch (e) {
    console.log(e);
  }
};

export const updatePhysicianData = async (data) => {
  try {
    const { email, adminSettings, isRemoveVacation } = data;
    const res = await axiosInstance.patch(`/api/doctor/update/admin-settings`, {
      physician: email,
      adminSettings,
      isRemoveVacation,
    });
    return res.data;
  } catch (e) {
    console.log('Error update patients details: \n', e);
    return null;
  }
};

export const updatePhysicianDetails = async (data) => {
  try {
    const { adminSettings } = data;
    const res = await axiosInstance.put(`/api/doctor/admin-settings-v2`, {
      adminSettings,
    });
    return res.data;
  } catch (e) {
    console.log('Error update physician details: \n', e);
    return null;
  }
};

export const savePhysicianImage = async ({ file, type }) => {
  try {
    const res = await axiosInstance.post(`/api/doctor/picture/${type}`, file);
    return res.data;
  } catch (e) {
    console.log('Error save physician stamp: \n', e);
    return null;
  }
};

export const getPhysicianImage = async (fileName) => {
  const res = await axiosInstance.get(`/api/annotations/document/${fileName}`, {
    timeout: 5000,
    responseType: 'blob',
  });
  const blobLink = URL.createObjectURL(res.data);
  return blobLink;
};

export async function getPatientDetailsTranslated(id, lang) {
  try {
    const res = await axiosInstance.get(
      `/api/requests/translated/${id}/${lang}`,
      {
        timeout: 600000,
      }
    );
    const details = res.data;
    if (res.data?.status === 403) {
      return 'OTHER_PRIVATE_POOL';
    }
    return details;
  } catch (err) {
    const { data = {} } = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {
      console.log('Error update patients details: \n', err);
      throw new Error('request failed');
    }
  }
}

let cancelGetPatientsRequest;
export async function getPatients(params) {
  let res = {};

  try {
    if (cancelGetPatientsRequest) {
      cancelGetPatientsRequest();
    }
    res = await axiosInstance.post(`/api/requests`, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetPatientsRequest = c;
      }),
    });
    return res.data;
  } catch (err) {
    console.log('Error getting customers from API:', err.response.status, err);
    const { data = {} } = (err && err.response) || {};
    if (data.statusCode === 401) {
      return null;
    } else if (data.statusCode === 403) {
      return 'OTHER_PRIVATE_POOL';
    } else {
      return [];
    }
  }
}

export async function postDiagnosis(data) {
  try {
    const { modified_by, uuid, status, notify = false } = data;
    const res = await axiosInstance.post(
      `/api/requests/post-diagnosis/${uuid}`,
      { modified_by, notify, status }
    );
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getAnnotation(id) {
  try {
    const res = await axiosInstance.get(`/api/annotations/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function addAnnotation(id, data) {
  try {
    const res = await axiosInstance.post(`/api/annotations/${id}`, data);
    return res.data;
  } catch (err) {
    console.log(err);
    return { data: null, err: err };
  }
}

export async function changeStatus(uuid, body) {
  try {
    await axiosInstance.post(`/api/requests/status/${uuid}`, body);
    return true;
  } catch (err) {
    console.log('DD Error: \n', err);
    return false;
  }
}

export async function changeLang(payload) {
  try {
    return await axiosInstance.put('/api/doctor/update/user-language', payload);
  } catch (e) {
    console.log(e);
  }
}

export async function getDiseases(vendor) {
  try {
    const res = await axiosInstance.get(`/api/diseases/${vendor}`);
    return res.data;
  } catch (err) {
    if (err.response.status === 401) {
      return null;
    }

    console.log('Error update patients details: \n', err);
    return [];
  }
}

async function getTemplates(user) {
  const url = user ? `/api/templates/${user}` : `/api/templates`;
  try {
    const res = await axiosInstance.get(url);
    return res.data || [];
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function addTemplate(data) {
  try {
    const res = await axiosInstance.post(`/api/templates`, data);
    return res.data.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function editTemplate(payload) {
  try {
    const res = await axiosInstance.patch(
      `/api/templates/${payload.id}`,
      payload
    );
    return res.data.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function deleteTemplate(id) {
  try {
    const res = await axiosInstance.delete(`/api/templates/${id}`);
    return res.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function getGroups() {
  try {
    const res = await axiosInstance.get(`/api/groups`);
    return res.data;
  } catch (err) {
    if (err.response.status === 401) {
      return null;
    }

    console.log('Error get groups: \n', err);
    return [];
  }
}

export async function addGroup(data) {
  try {
    const res = await axiosInstance.post(`/api/groups`, data);
    return res.data.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function editGroup(id, data) {
  try {
    const res = await axiosInstance.patch(`/api/groups/${id}`, data);
    return res.data.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function deleteGroup(id) {
  try {
    const res = await axiosInstance.delete(`/api/groups/${id}`);
    return res.data[0];
  } catch (err) {
    console.log(err);
    return { err: err, data: null };
  }
}

export async function nextAvailableCase(physician) {
  try {
    const res = await axiosInstance.get(`/api/requests/nac/${physician}`, {
      timeout: 600000,
    });
    return res.data;
  } catch (e) {
    console.log(e);
    return '';
  }
}

export const getCasesBanchInfo = async (caseId) => {
  const { data } = await axiosInstance.get(`/api/requests/banch`, {
    params: { caseId },
  });
  return data;
};

export async function changePendingStatus({
  uuid,
  action,
  description,
  physician,
}) {
  const res = await axiosInstance.post(`/api/changes-pending-status/${uuid}`, {
    action,
    description,
    physician,
  });
  return res.data;
}

export async function getPendingDescriptions({ uuid }) {
  const res = await axiosInstance.get(`/api/pending-descriptions/${uuid}`);
  return res.data;
}

export async function subscriptionNACAnalytic(data) {
  const res = await axiosInstance.post(`/api/subscribe-nac-analytic`, data);
  return res ? res.data : null;
}

export async function createTreatmentPlanV3(payload) {
  try {
    const res = await axiosInstance.post(
      `/api/annotations/v3/${payload.status}/${payload.caseId}`,
      payload.form
    );
    return { response: res.data};
  } catch (error) {
    console.log('error', error);
    console.error('error.response', error?.response);
    return { error: error?.response?.data };
  }
}

export async function getOncologistDetails(physician_code) {
  try {
    const res = await axiosInstance.get(`api/doctor/details/${physician_code}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const getPdfPreviewUrl = async (uuid, body) => {
  const res = await axiosInstance.post(
    `api/requests/pdf_preview/${uuid}`,
    body,
    { responseType: 'blob' }
  );
  const url = URL.createObjectURL(
    new Blob([res.data], { type: 'application/pdf' })
  );
  return url;
};

export const getPatientHistory = async (user_identifier) => {
  const { data } = await axiosInstance.get(`/api/history/${user_identifier}`, {
    timeout: 60000,
  });
  return data;
};

export default {
  switchSMSNotification,
  changeStatus,
  getPatientDetailsTranslated,
  getDiseases,
  addTemplate,
  editTemplate,
  deleteTemplate,
  getGroups,
  editGroup,
  deleteGroup,
  addGroup,
  getAnnotation,
  postDiagnosis,
  addAnnotation,
  changeLang,
  getTemplates,
  nextAvailableCase,
  getCasesBanchInfo,
  getDoctorDetails,
  updatePhysicianData,
  updatePhysicianDetails,
  savePhysicianImage,
  getPhysicianImage,
  subscriptionNACAnalytic,
  changePendingStatus,
  getPendingDescriptions,
  createTreatmentPlanV3,
  getOncologistDetails,
};
