import * as AUDIT_ANNOTATIONS_TYPES from './auditAnnotations.types';

export const getAuditAnnotationsByUuidRequest = (uuid) => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST,
    payload: uuid,
  };
};
export const getAuditAnnotationsByUuidSuccess = (annotations) => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_SUCCESS,
    payload: { annotations },
  };
};
export const getAuditAnnotationsByUuidFail = () => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST_FAIL,
  };
};

export const createAuditAnnotationRequest = ({ uuid, body }) => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_REQUEST,
    payload: { uuid, body },
  };
};
export const createAuditAnnotationSuccess = (annotation) => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_SUCCESS,
    payload: { annotation },
  };
};
export const createAuditAnnotationFail = () => {
  return {
    type: AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_FAIL,
  };
};
