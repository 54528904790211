import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import * as SETTINGS_TYPES from './settings.types';
import { getPrefixForLang } from '../../helpers/getPrefixForLang';
import i18n from '../../i18n';
import { changeLang } from '../../services/main.service';
import { getPhysicianPrefix } from '../admin/admin.selectors';
import { setUserLanguage } from './settings.actions';
import * as moment from 'moment';
import { updatePhysicianPrefix } from '../admin/admin.actions';

function* setUserLng({ payload: { language }, payload }) {
  yield call(changeLang, payload);
  const currentPrefix = yield select(getPhysicianPrefix);
  yield put(updatePhysicianPrefix(getPrefixForLang(currentPrefix, language)));
  yield i18n.changeLanguage(language);
  moment.updateLocale(language);
  yield put(setUserLanguage(language));
}

function* watchSetUserLang() {
  yield takeLatest(SETTINGS_TYPES.CHANGE_USER_LANGUAGE, setUserLng);
}
const settingsSagas = [fork(watchSetUserLang)];

export default settingsSagas;
