import * as PHYSICIANS_TYPES from './physicians.types';

const initialState = {
  physicians: [],
  selectedPhysician: null,
};

const physiciansReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PHYSICIANS_TYPES.SET_PHYSICIANS: {
      return {
        ...state,
        physicians: payload,
      };
    }
    case PHYSICIANS_TYPES.SET_SELECTED_PHYSICIAN: {
      return {
        ...state,
        selectedPhysician: payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default physiciansReducer;
