import styled from 'styled-components';
import Button from '../shared/Button';
import CustomInput from '../shared/CustomInput';

export const LoginInput = styled(CustomInput)`
  margin-top: 40px;
  input {
    height: 24px;
    padding-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    color: #272733;
  }
  input:-webkit-autofill,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:hover {
    box-shadow: 0 0 0 30px white inset;
  }
  .MuiInputBase-root::before,
  .MuiInputBase-root::after {
    border-bottom: 2px solid #b0eeef !important;
  }

  input::placeholder {
    color: '#858585';
  }
  p.MuiFormHelperText-root {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const ResetPasswordButton = styled(Button)`
  padding: 0;
  font-size: 12px;
  font-weight: 400;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
`;

export const StyledForm = styled.form`
  max-width: 360px;
`;

export const Content = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 160px;
`;

export const Footer = styled.footer`
  margin-top: auto;
  margin-bottom: 20px;
`;

export const FormErrorText = styled.span`
  margin-top: 8px;
  color: #d6341f;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;
