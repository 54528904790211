import * as PATIENT_DETAILS_TYPES from './patientDetails.types';

import { SYSTEM_STATUSES } from '../../components/helpers/groups';

const getCurrentCaseTreatmentEffect = (
  annotations,
  case_uuid,
  status,
  targetStatuses
) => {
  if (!targetStatuses.includes(status) || !case_uuid || !annotations.length) return null;
  const lastAnnotation = annotations[annotations.length - 1];
  if (!lastAnnotation) return null;
  const description = annotations.reduce((acc, annotation) => {
    const description = annotation?.treatment_effect?.description;
    if (description) {
      acc = acc ? `${acc}, ${description}` : description;
    }
    return acc;
  }, '');
  return {
    uuid: lastAnnotation.uuid,
    treatment_efficiency: {
      ...lastAnnotation.treatment_effect,
      description,
    },
  };
};

const initialState = {
  createTreatmentPlanLoading: false,
  createTreatmentPlanSuccess: null,
  createTreatmentPlanError: null,
  patientDetails: {
    currentTab: 'tab-0',
  },
  isDiagnoseLoaded: true,
  patientDetailsTranslated: null,
  currentCasesArray: [],
  currentCaseIndex: 0,
  isAnnotationsFetchLoading: true,
  descriptions: null,
  annotations: [],
  unresolvedTreatment: null, // IDEA: ? think to assemble logic for unresolvedTreatment and resolvedTreatment to one ?
  resolvedTreatment: null,
  response: {},
  status: 1,
  created_at: '',
  modified_by: '',
  case_id: '',
  case_uuid: '',
  private_pool: '',
  user_identifier: '',
  vendor_id: '',
  diagnosed_by: '',
  doctorDataDiagnosedBy: null,
  load_data: false,
  error: null,
  isPendingLoading: false,
  isUnblockPendingLoading: false,
  pendingError: null,
  isImageViewed: false,
  mlDiagnosisProbabilities: null,
  isImagesLoading: true,
  userHash: null,
  isLoadingSaveCase: false,
  patient_id: '',
  patient_email: '',
  physician_code: null,
  messagesToPatientModal: {
    open: false,
    title: '',
  },
  redFlags: [],
  triage: null
};

export default function patientDetailsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_START:
      return {
        ...state,
        createTreatmentPlanLoading: true,
        createTreatmentPlanSuccess: false,
        createTreatmentPlanError: initialState.createTreatmentPlanError,
      };
    case PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_RESET:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanSuccess: false,
        createTreatmentPlanError: initialState.createTreatmentPlanError,
      };
    case PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_SUCCESS:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanSuccess: true,
      };
    case PATIENT_DETAILS_TYPES.CREATE_TREATMENT_PLAN_FAIL:
      return {
        ...state,
        createTreatmentPlanLoading: false,
        createTreatmentPlanError: payload,
      };
    case PATIENT_DETAILS_TYPES.SET_IMAGES_LOADING:
      return {
        ...state,
        isImagesLoading: payload,
      };
    case PATIENT_DETAILS_TYPES.SET_ML_DIAGNOSIS_PROBABILITY:
      return {
        ...state,
        mlDiagnosisProbabilities: payload,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_START:
      return {
        ...state,
        isUnblockPendingLoading: true,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_SUCCESS:
    case PATIENT_DETAILS_TYPES.UPDATE_UNBLOCK_CASES_BATCH_FAIL:
      return {
        ...state,
        isUnblockPendingLoading: false,
        descriptions: null,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_START:
      return {
        ...state,
        isPendingLoading: true,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_SUCCESS:
      return {
        ...state,
        isPendingLoading: false,
        isUnblockPendingLoading: false,
        descriptions: payload,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_BLOCK_CASES_BATCH_FAIL:
      return {
        ...state,
        isPendingLoading: false,
        isUnblockPendingLoading: false,
        pendingError: payload,
      };
    case PATIENT_DETAILS_TYPES.INIT_PATIENT_DETAILS: {
      return { ...initialState, currentCasesArray: state.currentCasesArray };
    }
    case PATIENT_DETAILS_TYPES.UPDATE_CURRENT_CASE_INDEX:
      return {
        ...state,
        currentCaseIndex: payload,
      };
    case PATIENT_DETAILS_TYPES.UPDATE_AWAITING_CASES_ARRAY:
      return {
        ...state,
        currentCasesArray: payload.array,
        currentCaseIndex: payload.index,
        case_id: '',
      };
    case PATIENT_DETAILS_TYPES.FILL_AWAITING_CASES_ARRAY:
      return {
        ...state,
        currentCasesArray: payload,
      };
    case PATIENT_DETAILS_TYPES.CHANGE_STATUS_STATE: {
      const { status, uuid } = payload;
      return {
        ...state,
        status,
        currentCasesArray: state.currentCasesArray.map((c) =>
          c.uuid === uuid ? { ...c, status } : c
        ),
      };
    }
    case PATIENT_DETAILS_TYPES.SET_PATIENT_DETAILS: {
      const data = payload;
      return {
        ...state,
        patientDetails: data.request,
        patientDetailsTranslated: data.requestTranslated,
        annotations: [],
        unresolvedTreatment: null,
        resolvedTreatment: null,
        response: data.response,
        status: data.status,
        created_at: data.created_at,
        modified_by: data.modified_by,
        diagnosed_by: data.diagnosed_by,
        doctorDataDiagnosedBy: data.doctorDataDiagnosedBy,
        private_pool: data.private_pool,
        case_id: data.case_id,
        case_uuid: data.case_uuid,
        user_identifier: data.user_identifier,
        vendor_id: data.vendor_id,
        descriptions: data.description,
        userHash: data.user_hash || null,
        patient_id: data.patient_id,
        patient_name: data.patient_name,
        patient_email: data.patient_email,
        physician_code: data.physician_code,
        redFlags: data.redFlags || [],
        triage: data.triage,
        createTreatmentPlanSuccess: data.createTreatmentPlanSuccess,
      };
    }
    case PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_START: {
      return {
        ...initialState,
        case_id: null,
        status: 2,
        isImageViewed: false,
        currentCasesArray: state.currentCasesArray,
        currentCaseIndex: state.currentCaseIndex,
        load_data: true,
      };
    }
    case PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_END_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    case PATIENT_DETAILS_TYPES.PATIENT_DETAILS_LOAD_END: {
      return {
        ...state,
        load_data: false,
      };
    }
    case PATIENT_DETAILS_TYPES.FETCH_ANNOTATIONS_START:
      return {
        ...state,
        error: null,
        isAnnotationsFetchLoading: true,
      };
    case PATIENT_DETAILS_TYPES.SET_ANNOTATIONS: {
      return {
        ...state,
        isAnnotationsFetchLoading: false,
        annotations: payload,
        unresolvedTreatment: getCurrentCaseTreatmentEffect(
          payload,
          state.case_uuid,
          state.status,
          [SYSTEM_STATUSES.TER]
        ),
        resolvedTreatment: getCurrentCaseTreatmentEffect(
          payload,
          state.case_uuid,
          state.status,
          [
            SYSTEM_STATUSES.COMPLETELY_CLOSED,
            SYSTEM_STATUSES.IN_PROCESS,
            SYSTEM_STATUSES.PHYSICIAN_ANSWER,
            SYSTEM_STATUSES.REQUEST_ADDITIONAL_DATA,
            SYSTEM_STATUSES.VISIT_CLINIC,
          ]
        ),
      };
    }
    case PATIENT_DETAILS_TYPES.SET_MESSAGES_TO_PATIENT_DIALOG:
      return {
        ...state,
        messagesToPatientModal: payload,
      };
    case PATIENT_DETAILS_TYPES.SET_IS_IMAGE_VIEWED:
      return {
        ...state,
        isImageViewed: payload,
      };
    default: {
      return state;
    }
  }
}
