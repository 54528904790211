import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    maxHeight: '38px !important',
    background: 'linear-gradient(180deg, #0381A0 0%, #0381A0 100%)',
    boxShadow: 'none !important',
    '& > span:first-child': {
      fontSize: '16px !important',
      lineHeight: '24px !important',
      fontWeight: '700 !important',
      textTransform: 'none',
    },

    '&:disabled > span:first-child': {
      color: (props) => props.disabledColorText,
    },
    
    '&:disabled': {
      background: '#CECECE',
    },

    '&:hover': {
      cursor: 'pointer',
      background: 'linear-gradient(180deg, #016D87 0%, #0381A0 100%)',
      transition: 'none !important',
    },

    '&:active': {
      cursor: 'pointer',
      background: '#016D87',
      transition: 'none !important',
    },

    '&:focus': {
      outline: 'none',
    },
  },
});
