export const GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST =
  'GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST';
export const GET_AUDIT_ANNOTATIONS_BY_UUID_SUCCESS =
  'GET_AUDIT_ANNOTATIONS_BY_UUID_SUCCESS';
export const GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST_FAIL =
  'GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST_FAIL';
export const CREATE_AUDIT_ANNOTATION_REQUEST =
  'CREATE_AUDIT_ANNOTATION_REQUEST';
export const CREATE_AUDIT_ANNOTATION_SUCCESS =
  'CREATE_AUDIT_ANNOTATION_SUCCESS';
export const CREATE_AUDIT_ANNOTATION_FAIL = 'CREATE_AUDIT_ANNOTATION_FAIL';
