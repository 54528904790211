export const VALIDATE_RECEIVED_REGISTRATION_PARAMETERS =
  'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS';
export const VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_START =
  'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_START';
export const VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_SUCCESS =
  'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_SUCCESS';
export const VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_FAIL =
  'VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_FAIL';

export const SAVE_NEW_PHYSICIAN_PASSWORD = 'SAVE_NEW_PHYSICIAN_PASSWORD';
export const SAVE_NEW_PHYSICIAN_PASSWORD_START =
  'SAVE_NEW_PHYSICIAN_PASSWORD_START';
export const SAVE_NEW_PHYSICIAN_PASSWORD_SUCCESS =
  'SAVE_NEW_PHYSICIAN_PASSWORD_SUCCESS';
export const SAVE_NEW_PHYSICIAN_PASSWORD_FAIL =
  'SAVE_NEW_PHYSICIAN_PASSWORD_FAIL';
export const SAVE_NEW_PHYSICIAN_PASSWORD_INVALID =
  'SAVE_NEW_PHYSICIAN_PASSWORD_INVALID';

export const CREATE_NEW_PASSWORD_REQUEST = 'CREATE_NEW_PASSWORD_REQUEST';
export const CREATE_NEW_PASSWORD_START = 'CREATE_NEW_PASSWORD_START';
export const CREATE_NEW_PASSWORD_SUCCESS = 'CREATE_NEW_PASSWORD_SUCCESS';
export const CREATE_NEW_PASSWORD_FAIL = 'CREATE_NEW_PASSWORD_FAIL';
export const RESET_CREATE_NEW_PASSWORD_ERROR =
  'RESET_CREATE_NEW_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const LOG_OUT = 'LOG_OUT';
export const SET_ENV_SETTINGS = 'SET_ENV_SETTINGS';
