import { fork, put, takeLatest, select, call } from 'redux-saga/effects';
import rest from '../../services/main.service';
import * as DISEASES_TYPES from './diseases.types';
import { getLoadDiseasesSuccess } from './diseases.actions';

function* watchLoadDiagnosesWORK({ payload }) {
  try {
    const loaded_diseases = yield select(
      (state) => state.diseases && state.diseases.loaded_diseases
    );
    if (!Array.isArray(loaded_diseases) || !loaded_diseases.length) {
      const diseases = yield call(rest.getDiseases, payload);
      const data = diseases.map((d) => ({
        ...d,
        name: d.name.toUpperCase(),
      }));
      yield put(getLoadDiseasesSuccess(data));
    }
  } catch (e) {
    console.log(`watchLoadDiagnosesWORK -> failed`);
  }
}

function* watchLoadDiagnosesRequest() {
  yield takeLatest(
    DISEASES_TYPES.LOAD_DISEASES_REQUEST,
    watchLoadDiagnosesWORK
  );
}

const diseaseSagas = [fork(watchLoadDiagnosesRequest)];
export default diseaseSagas;
