import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import './App.css';
import { connect } from 'react-redux';
import * as serverAuth from './services/auth.service';
import 'moment/locale/he';
import { ThemeProvider } from '@material-ui/styles';
import Viewer from 'react-viewer';
import { withTranslation } from 'react-i18next';
import ToastNotification from './components/shared/ToastNotification';
import { createTheme } from '@material-ui/core/styles';
import asyncComponent from './hoc/asyncComponent';
import LoadingBlurredScreen from './components/shared/LoadingBlurredScreen';
import LoginPage from './components/Login/Login';
import { AUTHENTICATED_RESPONSE } from './components/Login/constants';
import {
  resetAdminSettings,
  setAdminSettings,
} from './store/admin/admin.actions';
import { DEFAULT_VACATION } from './store/admin/admin.reducer';
import { getCurrentPrivatePool } from './store/patientDetails/patientDetails.selectors';
import { getDir, ROUTES } from './helpers/route.helper';
import { updateAnalyticData } from './helpers/sagas.helper';
import { setEnvSettings } from './store/auth/auth.actions';
import 'slick-carousel/slick/slick.css';
import { useAutoLogout } from './hooks/useAutoLogout';
import {
  closeLightBox,
  setSettingsUser,
  setUserSetings,
  updateIsOnVacation,
} from './store/settings/settings.actions';
import { EDoctorPermissions, isSkintalks } from './helpers/constants';
import { setIsImageViewed } from './store/patientDetails/patientDetails.actions';
import { changeLng } from './helpers/translations';
import { getLanguageDir } from './store/settings/settings.selectors';

const AsyncPatientList = asyncComponent(() => {
  return import('./components/PatientList/PatientList');
});
const AsyncCaseEditPage = asyncComponent(() => {
  return import('./components/CaseEdit');
});
const AsyncAdminPage = asyncComponent(() => {
  return import('./components/Admin');
});
const AsyncRegistrationPage = asyncComponent(() => {
  return import('./components/Registration');
});
const AsyncForgotPasswordPage = asyncComponent(() => {
  return import('./components/ForgotPassword');
});
const AsyncCreatePasswordPage = asyncComponent(() => {
  return import('./components/CreatePassword');
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#0392B5',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Heebo', 'Arial', 'sans-serif'].join(','),
  },
  direction: 'rtl',
});

const getPathName = (pathName) =>
  pathName === '/user-management' ? pathName : '/patients';

const getComponent = ({ user, isValidationCode, isValidated }) => {
  const to = { pathname: '/login' };
  if (user) {
    to.pathname = isValidated
      ? getPathName(window.location.pathname)
      : '/user-management-validate';

    if (isValidationCode) {
      to.pathname = `/create-password`;
      to.search = `?email=${user}`;
    }
  }
  return <Redirect to={to} />;
};

class App extends Component {
  timeOutInstanceHelper = null;
  timeOutInstanceHelper2 = null;
  clickEventList = null;
  clickEventList2 = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    if (!this.props.isScreenBlocked) {
      this.init();
    }
  }

  componentWillUnmount() {
    if (this.timeOutInstanceHelper) {
      clearTimeout(this.timeOutInstanceHelper);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isSkintalks &&
      localStorage.getItem('lang') !== prevProps?.i18n?.language
    ) {
      const iframe = document.getElementById('jsd-widget');
      if (iframe && (iframe.contentDocument || iframe.contentWindow.document)) {
        iframe.contentDocument.getElementsByTagName('p')[0].innerText =
          this.props.t('jira.contactUs');
      }
    }
  }

  jiraSupport() {
    const jiraHelpdesk = (callback) => {
      const jhdScript = document.createElement('script');
      jhdScript.type = 'text/javascript';
      jhdScript.setAttribute('data-jsd-embedded', null);
      jhdScript.setAttribute(
        'data-key',
        'f48cef0d-cb68-4657-a6ab-66de18d03fa8'
      );
      jhdScript.setAttribute(
        'data-base-url',
        'https://jsd-widget.atlassian.com'
      );
      jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      if (jhdScript.readyState) {
        // old IE support
        jhdScript.onreadystatechange = function () {
          if (
            jhdScript.readyState === 'loaded' ||
            jhdScript.readyState === 'complete'
          ) {
            jhdScript.onreadystatechange = null;
            callback();
          }
        };
      } else {
        //modern browsers
        jhdScript.onload = function () {
          callback();
        };
      }
      document.getElementsByTagName('head')[0].appendChild(jhdScript);
    };

    jiraHelpdesk(function () {
      const DOMContentLoaded_event = document.createEvent('Event');
      DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);
    });
  }

  handleIframeHtml(doctor) {
    try {
      if (this.timeOutInstanceHelper) {
        clearTimeout(this.timeOutInstanceHelper);
        clearTimeout(this.timeOutInstanceHelper2);
      }
      const iframe = document.getElementById('jsd-widget');
      if (!iframe) {
        this.timeOutInstanceHelper = setTimeout(
          () => this.handleIframeHtml(doctor),
          1000
        );
        return;
      }
      const iframeDoc =
        iframe && (iframe.contentDocument || iframe.contentWindow.document);
      iframe.style.bottom = '65px';
      const handleIFrame = () => {
        if (iframeDoc) {
          const helperButton =
            iframe.contentWindow.document.getElementById('help-button');
          const el = () => {
            try {
              this.timeOutInstanceHelper2 = setTimeout(() => {
                const iframe2 = document.getElementById('jsd-widget');
                const close = iframe2.contentWindow.document.querySelector(
                  '.header-close-icon-container'
                );
                const unputEmail = iframe2.contentWindow.document.querySelector(
                  '#react-root div .form-container form.help-form div.ak-field-group #email'
                );
                if (unputEmail) {
                  unputEmail.value = doctor;
                }
                if (close) {
                  const closeEL = () => {
                    this.props.history.go(0);
                  };
                  close.removeEventListener('click', closeEL);
                  this.clickEventList2 = close.addEventListener(
                    'click',
                    closeEL
                  );
                }
              }, 10);
            } catch (e) {}
          };
          if (helperButton && helperButton.addEventListener) {
            helperButton.removeEventListener('click', el);
            this.clickEventList = helperButton.addEventListener('click', el);
          }
        }
      };
      if (iframeDoc) {
        handleIFrame(doctor);
      }
    } catch (e) {}
  }

  handleJiraAfterLogin(doctor) {
    this.jiraSupport();
    this.handleIframeHtml(doctor);
  }

  init = async () => {
    const { dispatch, t } = this.props;

    console.log('API: ', process.env.REACT_APP_API);

    const elem = document.getElementById('lang_direction');
    const dir = elem.getAttribute('dir');
    const languageBasedDir =
      localStorage.getItem('lang') === 'he' ? 'rtl' : 'ltr';

    if (languageBasedDir !== dir) {
      const bodyElem = document.getElementById('lang_direction');
      bodyElem.setAttribute('dir', languageBasedDir);
    }

    let userData;

    const envSettings = await serverAuth.getEnvSettings();

    if (!this.props.user && !this.props.isScreenBlocked) {
      if (this.props.loginProcess) {
        return;
      }

      dispatch(setEnvSettings(envSettings));
      userData = await serverAuth.isAuth();

      if (userData?.status === AUTHENTICATED_RESPONSE) {
        dispatch(updateIsOnVacation(userData.isOnVacation));
        if (userData.permission === EDoctorPermissions.superuser) {
          this.jiraSupport();
          this.handleIframeHtml(userData.doctor_email);
        }

        if (
          userData.admin_settings &&
          !userData.admin_settings.physicianPrefix
        ) {
          userData.admin_settings.physicianPrefix = t(
            'managementPage.physicianPrefix1'
          );
        }

        dispatch(
          setSettingsUser({
            user: userData.doctor_email,
            vendor: userData.vendor_id,
            permission: userData.permission,
            admin_settings: userData.admin_settings,
            isValidated: userData.is_validated,
            isValidationCode: userData.is_validation_code,
          })
        );
        let vacation = DEFAULT_VACATION;
        let isVacationSet = false;
        if (userData.admin_settings && userData.admin_settings.vacation) {
          const defDate = new Date().toISOString();
          const { start_vacation = defDate, end_vacation = defDate } =
            userData.admin_settings.vacation || {};
          isVacationSet = true;
          vacation = {
            start_vacation,
            end_vacation,
          };
        }
        dispatch(
          setAdminSettings({
            adminSettings: userData.admin_settings,
            admin: userData.doctor_email,
            user: userData.doctor_email,
            is_active: userData.is_active,
            permission: userData.permission,
            vacation,
            isVacationSet,
          })
        );

        const language =
          localStorage.getItem('lang') ?? userData.settings?.language ?? 'en';
        dispatch(setUserSetings({ ...userData.settings, language }));
        await changeLng(language);
        const bodyElem = document.getElementById('lang_direction');
        bodyElem.setAttribute('dir', getDir(language));
      } else {
        dispatch(setSettingsUser({ user: '', vendor: '', permission: '' }));
        dispatch(resetAdminSettings());
        dispatch(setUserSetings(null));
      }
      this.setState({ loading: false });
    } else {
      dispatch(setSettingsUser({ user: '', vendor: '', permission: '' }));
      dispatch(resetAdminSettings());
    }
  };

  render() {
    const { lightboxShow, images, currentImageIndex, dispatch, direction } =
      this.props;
    if (this.state.loading) {
      return null;
    }
    const themeDir = { ...theme, direction };
    let viewerSource = [];
    if (lightboxShow && images) {
      viewerSource = images.map((d) => ({ src: d.url, alt: d.title }));
    }

    return (
      <ThemeProvider theme={themeDir}>
        <Router>
          {Array.isArray(viewerSource) && viewerSource.length ? (
            <div
              style={{
                textAlign: 'left',
                direction: 'ltr',
              }}
            >
              <Viewer
                visible={lightboxShow}
                zoomSpeed="0.1"
                minScale={1}
                defaultScale={3}
                rotatable={true}
                showTotal={true}
                onChange={(image, index) => {
                  if (index + 1 === images.length) {
                    dispatch(setIsImageViewed(true));
                  }
                }}
                activeIndex={currentImageIndex}
                onClose={() => dispatch(closeLightBox())}
                images={viewerSource}
                style={{
                  textAlign: 'left',
                  direction: 'ltr',
                }}
              />
            </div>
          ) : null}
          <div className="MainContent">
            <Switch>
              <Route path={ROUTES.LOGIN}>
                {this.props.user ? (
                  getComponent(this.props)
                ) : (
                  <LoginPage
                    handleJiraAfterLogin={(email) => {
                      const newFn = this.handleJiraAfterLogin.bind(this);
                      newFn(email);
                    }}
                  />
                )}
              </Route>
              <Route
                path={'/create-password'}
                component={AsyncCreatePasswordPage}
              />
              <ProtectRoute
                path={ROUTES.PATIENTS_LIST}
                authUser={this.props.user}
                component={AsyncPatientList}
                exact
              />
              <Route path={'/register'} component={AsyncRegistrationPage} />
              <Route
                path={'/forgot-password'}
                component={AsyncForgotPasswordPage}
              />
              <ProtectRoute
                path={ROUTES.PATIENTS_CASE_EDIT}
                authUser={this.props.user}
                component={AsyncCaseEditPage}
                exact
              />
              <ProtectRoute
                path="/user-management-validate/"
                authUser={this.props.user}
                component={AsyncAdminPage}
                exact
              />
              <ProtectRoute
                path="/user-management/"
                authUser={this.props.user}
                component={AsyncAdminPage}
                exact
              />
              <Route path="/">
                <Redirect to={ROUTES.PATIENTS_LIST} />
              </Route>
              <Route component={NoMatch} />
            </Switch>
          </div>
          <LoadingBlurredScreen />
          <ToastNotification />
        </Router>
      </ThemeProvider>
    );
  }
}

const NoMatch = ({ location }) => (
  <div className="notFound">
    <h3>
      No results found for
      <code> {location.pathname}</code>
    </h3>
  </div>
);

const ProtectRoute = ({ component: Component, authUser, ...rest }) => {
  useAutoLogout();

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  if (state.admin.admin || state.settings.user) {
    updateAnalyticData({ physician: state.admin.admin || state.settings.user });
  }

  return {
    user: state.settings.user,
    direction: getLanguageDir(state),
    userSettings: state.settings.userSettings,
    loginProcess: state.settings.loginProcess,
    lightboxShow: state.settings.lightboxShow,
    images: state.settings.images,
    currentImageIndex: state.settings.currentIndex,
    isScreenBlocked: state.settings.isScreenBlocked,
    privatePool: getCurrentPrivatePool(state),
    isValidated: state.settings.isValidated,
    isValidationCode: state.settings.isValidationCode,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)));
