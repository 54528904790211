import * as PATIENT_HISTORY_TYPES from './patientHistory.types';

export const getPatientHistoryDiseases = (user_identifier, user_hash) => ({
  type: PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES,
  payload: {user_identifier, user_hash},
});

export const getPatientHistoryDiseasesStart = () => ({
  type: PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_START,
});

export const getPatientHistoryDiseasesSuccess = (diseases) => ({
  type: PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_SUCCESS,
  payload: diseases,
});

export const getPatientHistoryDiseasesFail = (error) => ({
  type: PATIENT_HISTORY_TYPES.GET_PATIENT_HISTORY_DISEASES_FAIL,
  error: error,
});
