import React, { forwardRef, memo } from 'react';
import { StyledButton } from './styles';
const Button = forwardRef(
  (
    { children, onClick, variant, disabled, startIcon, endIcon, ...rest },
    ref
  ) => (
    <StyledButton
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      {startIcon}
      {children}
      {endIcon}
    </StyledButton>
  )
);

export default memo(Button);
