import React, { memo } from 'react';
import { StyledHeaderCommon, StyledLeftPartCommon } from './styles';
import myDermaLogoBig from '../../assets/svg/myDermaLogoBig.svg';
import Logo from './Logo';
import { isMyderma } from '../../helpers/constants';
import { AuthBar } from './AuthBar/AuthBar';

export const HeaderCommon = memo(() => {
  return (
    <StyledHeaderCommon>
      <StyledLeftPartCommon>
        <Logo />
        {isMyderma && <img src={myDermaLogoBig} alt="logoBig" />}
      </StyledLeftPartCommon>
      <AuthBar />
    </StyledHeaderCommon>
  );
});
