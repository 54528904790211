import { AI_MODELS } from '../../helpers/ai-models';

export const getLanguage = ({ settings }) =>
  settings?.userSettings?.language || 'he';
export const getLanguageDir = ({ settings }) =>
  settings?.userSettings?.dir || 'rtl';
export const loadingGlobalProcess = ({ settings }) => settings.isGlobalLoading;
export const getCurrentPhycisianEmail = ({ settings }) => settings.user;
export const getCurrentPhycisianPermission = ({ settings }) =>
  settings.permission;
export const getCurrentVendor = ({ settings }) => settings.vendor;
export const getCurrentAvailableField = ({ settings }) =>
  settings.isCurrentAvailableFaild;
export const getAiModel = ({ settings }) =>
  settings.aiModel || AI_MODELS.DEFAULT;
export const isFullValidated = ({ settings }) =>
  settings.isValidated && !settings.isValidationCode;
export const isValidated = ({ settings }) => settings.isValidated;
export const isValidatedCode = ({ settings }) => settings.isValidationCode;
export const isCurrentUserOnVacation = ({ settings }) => settings.isOnVacation;
