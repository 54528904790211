import { TENANTS } from '../../helpers/auth.helper';

export const getRegistrationValid = ({ auth }) => auth.isRegistrationValid;
export const getRegistrationValidationLoading = ({ auth }) =>
  auth.isRegistrationValidationLoading;
export const getRegistrationPasswordLoading = ({ auth }) =>
  auth.isRegistrationPasswordLoading;
export const getErrorValidation = ({ auth }) => auth.errorValidation;
export const getErrorPasswordAssign = ({ auth }) => auth.errorPasswordAssign;
export const getErrorCreatePassword = ({ auth }) => auth.errorCreatePassword;
export const getResetPasswordLoading = ({ auth }) =>
  auth.isResetPasswordLoading;
export const getResetPasswordAction = ({ auth }) => auth.resetPasswordAction;
export const getResetPasswordError = ({ auth }) => auth.resetPasswordError;
export const getTenant = ({ auth }) => auth.tenant || TENANTS.MACCABI;
