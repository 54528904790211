import { fork, put, takeLatest, call } from 'redux-saga/effects';

import * as AUDIT_ANNOTATIONS_TYPES from './auditAnnotations.types';
import {
  getAuditAnnotationsByUuidSuccess,
  getAuditAnnotationsByUuidFail,
  createAuditAnnotationSuccess,
  createAuditAnnotationFail,
} from './auditAnnotations.actions';
import {
  getAuditAnnotationsByUuid,
  createAuditAnnotation,
} from '../../services/audit.annotations.service';
import {
  changeStatusState,
  createTreatmentPlanSuccess,
} from '../patientDetails/patientDetails.actions';
import { postDiagnosis } from '../../services/main.service';

function* getAuditAnnotationsWork({ payload: uuid }) {
  try {
    const res = yield call(getAuditAnnotationsByUuid, uuid);
    yield put(getAuditAnnotationsByUuidSuccess(res));
  } catch (e) {
    yield put(getAuditAnnotationsByUuidFail());
    console.log(`getAuditAnnotationsWork -> failed`);
  }
}

function* createAuditAnnotationWork(action) {
  try {
    const {
      uuid,
      body: { status, updated_by },
      body,
    } = action.payload;

    const res = yield call(createAuditAnnotation, uuid, body);
    yield put(createAuditAnnotationSuccess(res));
    if (res) {
      yield call(postDiagnosis, {
        uuid,
        status,
        modified_by: updated_by,
        notify: true,
      });
    }
    yield put(changeStatusState(uuid, status));
    yield put(createTreatmentPlanSuccess());
  } catch (e) {
    yield put(createAuditAnnotationFail());
    console.log(`getAuditAnnotationsWork -> failed`);
  }
}

function* watchGettingAuditAnnotations() {
  yield takeLatest(
    AUDIT_ANNOTATIONS_TYPES.GET_AUDIT_ANNOTATIONS_BY_UUID_REQUEST,
    getAuditAnnotationsWork
  );
}
function* watchCreationAuditAnnotation() {
  yield takeLatest(
    AUDIT_ANNOTATIONS_TYPES.CREATE_AUDIT_ANNOTATION_REQUEST,
    createAuditAnnotationWork
  );
}

const auditAnnotationsSagas = [
  fork(watchGettingAuditAnnotations),
  fork(watchCreationAuditAnnotation),
];

export default auditAnnotationsSagas;
