import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Version, Wrapper } from './styles';
import { LangButton } from './LangButton/LangButton';
import { SettingsButton } from './SettingsButton/SettingsButton';
import { getCurrentPhycisianEmail } from '../../../store/settings/settings.selectors';
import packageJson from '../../../../package.json';

export const AuthBar = memo(() => {
  const user = useSelector(getCurrentPhycisianEmail);
  return (
    <Wrapper>
      <LangButton />
      {!!user && <SettingsButton />}
      <Version>{packageJson.version || '1.0.0'}</Version>
    </Wrapper>
  );
});
