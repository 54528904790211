import * as PHYSICIANS_TYPES from './physicians.types';

export const setPhysicians = (physicians) => ({
  type: PHYSICIANS_TYPES.SET_PHYSICIANS,
  payload: physicians,
});

export const loadPhysicians = (isFirstLoad = false) => ({
  type: PHYSICIANS_TYPES.LOAD_PHYSICIANS,
  payload: isFirstLoad,
});

export const setSelectedPhysician = (physician) => ({
  type: PHYSICIANS_TYPES.SET_SELECTED_PHYSICIAN,
  payload: physician,
});

export const loadSelectedPhysician = (id) => ({
  type: PHYSICIANS_TYPES.LOAD_SELECTED_PHYSICIAN,
  payload: id,
});
