import styled from 'styled-components';

const bgText = `
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `;

const outlinedVatiant = (color, hover, active) => `
color: ${color};
path, rect, line, circle {
  stroke: ${color};
}
:after {
  background: ${color};
}
:hover, :hover:after{
  background: ${hover};
}
:active, :active:after{
  background: ${active};
}
:disabled,:disabled:after {
  background: #cecece;
}
:disabled {
  path, rect, line, circle {
    stroke: #cecece;
  }
}
:hover, :active, :disabled{${bgText}}
`;

export const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: fit-content;
  min-width: fit-content;
  :active,
  :focus {
    outline: none;
  }

  background: transparent;
  color: #ffffff;
  position: relative;
  border: 2px solid transparent;
  :after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 2px;
    background: #0381a0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  ${({ variant }) => {
    switch (variant) {
      case 'secondary':
        return outlinedVatiant(
          '#0381A0',
          'linear-gradient(180deg, #016D87, #0381A0)',
          '#016D8'
        );
      case 'alarm':
        return outlinedVatiant(
          '#d6341f',
          'linear-gradient(180deg, #AA1D09, #DB361F)',
          '#891202'
        );
      case 'tertiary':
        return outlinedVatiant(
          '#65BED1',
          'linear-gradient(180deg, #0392B5, #65BED1)',
          '#0392B5'
        );
      case 'text':
        return `
        border:none;
        :after{
          content: none
        }
        background: #0381A0;
        :hover{
          background: linear-gradient(180deg, #016D87, #0381A0);
        }
        :active{
          background: #016D87;
        }
        :disabled,:disabled:after {
          background: #cecece;
        }

        &, :hover, :active, :disabled{${bgText}}
        `;
      case 'primary':
      default:
        return `
        border-radius: 4px;
        background: #0381a0;
        border:none;
        :after{
          content: none
        }
        :hover{
          background: linear-gradient(180deg, #016D87, #0381A0);
        }
        :active{
          background: #016D87;
        }
        :disabled {
          background: #cecece;
        }
        path, rect, line, circle {
          stroke: #ffffff;
        }
        `;
    }
  }}
`;
