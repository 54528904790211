import * as AUTH_TYPES from './auth.types';
import { envSettingsTypes, TENANTS } from '../../helpers/auth.helper';
import {
  ANALYTICS_DATE_LOCALSTORAGE_KEY,
  ENVIRONMENTS,
} from '../../helpers/sagas.helper';

const initialAuthState = {
  isRegistrationValid: false,
  isRegistrationValidationLoading: false,
  isCreatePasswordLoading: false,
  errorCreatePassword: null,
  isRegistrationPasswordLoading: false,
  errorValidation: null,
  errorPasswordAssign: null,
  isResetPasswordLoading: false,
  resetPasswordAction: null,
  resetPasswordError: null,
  version: '',
  tenant: TENANTS.MACCABI,
  nodeEnv: ENVIRONMENTS.PRODUCTION,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AUTH_TYPES.SET_ENV_SETTINGS: {
      window.analyticsUrl = action.payload[envSettingsTypes.ANALYTICS_URL];
      window.analyticsKey = ANALYTICS_DATE_LOCALSTORAGE_KEY;
      window.NODE_ENV = ENVIRONMENTS[action.payload.NODE_ENV];
      window.VERSION = action.payload[envSettingsTypes.VERSION];
      const tenant =
        action.payload.TENANT && TENANTS[action.payload.TENANT.toUpperCase()]
          ? TENANTS[action.payload.TENANT.toUpperCase()].toUpperCase()
          : null;
      window.tenant = tenant;
      return {
        ...state,
        version: action.payload[envSettingsTypes.VERSION],
        tenant: tenant ? tenant : TENANTS.MACCABI,
        nodeEnv: ENVIRONMENTS[action.payload.NODE_ENV],
      };
    }
    case AUTH_TYPES.RESET_PASSWORD_SUCCESS:
    case AUTH_TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isResetPasswordLoading: false,
        resetPasswordAction: action.payload.action,
        resetPasswordError: action.payload.error,
      };
    case AUTH_TYPES.RESET_PASSWORD_START:
      return {
        ...state,
        isResetPasswordLoading: true,
      };
    case AUTH_TYPES.CREATE_NEW_PASSWORD_START:
      return {
        ...state,
        isCreatePasswordLoading: true,
      };
    case AUTH_TYPES.CREATE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isCreatePasswordLoading: false,
        errorCreatePassword: null,
      };
    case AUTH_TYPES.CREATE_NEW_PASSWORD_FAIL:
      return {
        ...state,
        isCreatePasswordLoading: false,
        errorCreatePassword: action.payload.error,
      };
    case AUTH_TYPES.RESET_CREATE_NEW_PASSWORD_ERROR:
      return {
        ...state,
        errorCreatePassword: null,
      };
    case AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_START:
      return {
        ...state,
        isRegistrationPasswordLoading: true,
      };
    case AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_SUCCESS:
      return {
        ...state,
        isRegistrationPasswordLoading: false,
        errorPasswordAssign: null,
      };
    case AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_INVALID:
      return {
        ...state,
        errorPasswordAssign: null,
      };
    case AUTH_TYPES.SAVE_NEW_PHYSICIAN_PASSWORD_FAIL:
      return {
        ...state,
        isRegistrationPasswordLoading: false,
        errorPasswordAssign: action.payload.error,
      };
    case AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_START:
      return {
        ...state,
        isRegistrationValidationLoading: true,
      };
    case AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_SUCCESS:
      return {
        ...state,
        isRegistrationValidationLoading: false,
        isRegistrationValid: true,
        errorValidation: null,
      };
    case AUTH_TYPES.VALIDATE_RECEIVED_REGISTRATION_PARAMETERS_FAIL:
      return {
        ...state,
        isRegistrationValidationLoading: false,
        isRegistrationValid: false,
        errorValidation: action.payload.error,
      };
    default:
      return state;
  }
};

export default authReducer;
