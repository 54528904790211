import * as DISEASES_TYPES from './diseases.types';

import diseases from '../../assets/diseases.json';

const initialState = {
  diseases: Array.isArray(diseases)
    ? diseases.sort((a, b) => {
        if (!a.name || !b.name) {
          return 0;
        }
        if (a.name >= b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      })
    : [],
  loaded_diseases: [],
};

export default function diseasesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case DISEASES_TYPES.LOAD_DISEASES_REQUEST_SUCESS: {
      return {
        ...state,
        loaded_diseases: payload,
      };
    }
    default: {
      return state;
    }
  }
}
