import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: 'center',
    padding: '50px 0',
    borderRadius: '10px',
    gap: '25px',
  },
  title: {
    color: '#858585',
  },
  button: {
    width: 'fit-content',
    '& > span.MuiButton-label': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#fff',
    },
  },
  input: {
    width: '60%',
    '& input': {
      height: '24px',
      paddingBottom: '8px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#272733',
    },

    '& input::placeholder': {
      color: '#858585',
    },
  },
}));
