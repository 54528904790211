import * as SETTINGS_TYPES from './settings.types';
export const SETTINGS_LOADING_ACTIONS = Object.freeze({
  NEXT_CASE: 'NEXT_CASE',
  updatePageNumberWORK: 'updatePageNumberWORK',
  updatePageOffsetWORK: 'updatePageOffsetWORK',
  updatePageLimitSizeWORK: 'updatePageLimitSizeWORK',
  patientNotificationWORK: 'patientNotificationWORK',
  updateGlobalSearchStringWORK: 'updateGlobalSearchStringWORK',
  updatePatientsCaseIdWORK: 'updatePatientsCaseIdWORK',
  updatePatientsIdWORK: 'updatePatientsIdWORK',
  updateCreatedAtValueWORK: 'updateCreatedAtValueWORK',
  updateCreatedAtValueInDateRangeWORK: 'updateCreatedAtValueInDateRangeWORK',
  updateAgeValueWORK: 'updateAgeValueWORK',
  updateDiagnosedByValueWORK: 'updateDiagnosedByValueWORK',
  updateDiagnosedAtValueWORK: 'updateDiagnosedAtValueWORK',
  resetAllFilterWORK: 'resetAllFilterWORK',
  updateCreatedAtOrderWORK: 'updateCreatedAtOrderWORK',
  getPatientsWORK: 'getPatientsWORK',
  viewCase: 'viewCase',
});

export const loadingGlobalOn = (actionName) => ({
  type: SETTINGS_TYPES.loadingGlobalOn,
  payload: actionName,
});

export const loadingGlobalOff = (actionName) => ({
  type: SETTINGS_TYPES.loadingGlobalOff,
  payload: actionName,
});

export const updateIsOnVacation = (isOnVacation) => ({
  type: SETTINGS_TYPES.UPDATE_IS_ON_VACATION,
  payload: isOnVacation,
});

export const setAiModel = (modelName) => ({
  type: SETTINGS_TYPES.setAiModel,
  payload: modelName,
});

export const setSettingsUser = (user) => ({
  type: SETTINGS_TYPES.SET_USER,
  payload: user,
});

export const setIsValidationCode = (code) => ({
  type: SETTINGS_TYPES.SET_ISVALIDATIONCODE,
  payload: code,
});

export const setLoginProcess = (payload) => ({
  type: SETTINGS_TYPES.SET_LOGIN_PROCESS,
  payload,
});

export const setFailedAvailableCases = (payload) => ({
  type: SETTINGS_TYPES.SET_FAILED_AVAILABLE_CASES,
  payload,
});

export const setUserSetings = (settings) => ({
  type: SETTINGS_TYPES.SET_USER_SETTINGS,
  payload: settings,
});

export const setUserLanguage = (language) => ({
  type: SETTINGS_TYPES.SET_USER_LANGUAGE,
  payload: language,
});

export const openLightBox = (images, currentIndex) => ({
  type: SETTINGS_TYPES.OPEN_LIGHTBOX,
  payload: { images, currentIndex },
});

export const closeLightBox = () => ({
  type: SETTINGS_TYPES.CLOSE_LIGHTBOX,
});

export const changeUserLanguage = (user, language) => ({
  type: SETTINGS_TYPES.CHANGE_USER_LANGUAGE,
  payload: { user, language },
});
