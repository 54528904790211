import { fork, put, takeLatest, select, call } from 'redux-saga/effects';

import * as ANALYTIC_TYPES from './analytic.types';
import {
  subscribeNACAnalyticSuccess,
  subscriNACAnalyticStart,
} from './analytic.actions';
import { getCurrentPhycisianEmail } from '../settings/settings.selectors';
import rest from '../../services/main.service';

function* doSubscribeNACAnalyticWORK({ payload }) {
  try {
    yield put(subscriNACAnalyticStart());
    const physician = yield select(getCurrentPhycisianEmail);
    const analytic = yield call(rest.subscriptionNACAnalytic, {
      ...payload,
      email: physician,
    });
    if (analytic) {
      yield put(subscribeNACAnalyticSuccess(analytic));
    }
  } catch (e) {
    console.log('doSubscribeNACAnalyticWORK -> failed.');
  }
}

function* watchSubscriptionNACAnalytic() {
  yield takeLatest(
    ANALYTIC_TYPES.SUBSCRIBE_FOR_NAC_ANALYTIC_REQUEST,
    doSubscribeNACAnalyticWORK
  );
}

const analyticSagas = [fork(watchSubscriptionNACAnalytic)];

export default analyticSagas;
